import axiosIns from '@/service/httpClient';

class Api {
  uri: string;
  constructor(uri: string) {
    this.uri = uri;
  }

  get(query: object) {
    return axiosIns({
      url: `/${this.uri}`,
      method: 'get',
      params: query,
    });
  }

  getById(id: string | number) {
    return axiosIns({
      url: `/${this.uri}/${id}`,
      method: 'get',
    });
  }

  create(resource: object) {
    return axiosIns({
      url: `/${this.uri}`,
      method: 'post',
      data: resource,
    });
  }

  update(id: string, resource: object) {
    return axiosIns({
      url: `/${this.uri}/${id}`,
      method: 'put',
      data: resource,
    });
  }

  destroy(id: string) {
    return axiosIns({
      url: `/${this.uri}/${id}`,
      method: 'delete',
    });
  }
}

export { Api as default };
