<template>
  <section class="service">
    <h2 class="service-title text-center text-black">「備えの必要性」をデータで見ると</h2>
    <div class="row d-flex justify-content-between w-100 mx-0">
      <div class="col-md-4 pb-1 pb-md-0 service-text-area text-center">
        <img src="../../assets/images/graph1.png" alt="災害発生件数" />
      </div>
      <div class="col-md-4 pb-1 pb-md-0 service-text-area text-center">
        <img src="../../assets/images/graph2.png" alt="断水住戸数" />
      </div>
      <div class="col-md-4 pb-1 pb-md-0 service-text-area text-center">
        <img src="../../assets/images/graph3.png" alt="近隣トラブル" />
      </div>
    </div>
    <p class="service-content text-center text-black">
      あなたの身にも起こるかもしれない災害、近隣トラブルにしっかり備えて、安心・安全に暮らせる毎日を！
    </p>
    <h2 class="service-title text-center text-black">ご入会いただける方</h2>
    <p class="service-content text-black offset-md-4 col-md-4">
      ● 新規に住居ご入居をされる方
      <br />
      ● 提携する不動産会社からの紹介の方
      <br />
      ● 18歳以上の個人の方
    </p>

    <div class="service-image position-relative d-flex justify-content-center align-items-center">
      <div class="d-flex justify-content-center flex-column align-items-center">
        <h3 class="service-image-title text-white">
          月会費400円（税込440円）
          <br />
          契約単位は1ヶ月単位・解約金等はありません。
          <br />
          登録初月無料！
          <br />
        </h3>
        <p class="text-white">※近隣トラブル解決相談サポートは入会翌月からご利用いただけます。</p>
        <button
          class="image-btn btn-warning text-white"
          @click="$emit('scroll-to-register')"
          v-if="isAuthenticated"
        >
          今すぐ会員登録
        </button>
      </div>
    </div>
    <h2 class="service-title text-center">サービス内容</h2>

    <div class="service-item item-first row">
      <div class="content-left col-md-6">
        <h3 class="content-title">家具・家電レンタル5％割引</h3>
        <div class="content-text">
          <p>こんな時に便利な家具・家電のレンタルをいつでも5％割引で。</p>
          <p>
            ● 何年かごとに新品に交換したい。
            <br />
            ● 不意に故障！買い替えまでのつなぎに。
            <br />
            ● 単身赴任や学生一人暮らしに。
          </p>
          <p>レンタルなら</p>
          <p>
            ・ 故障しても修理や交換は無料。
            <br />
            ・ 家具や大型家電の送料無料・配置無料。
          </p>
        </div>
      </div>
      <div class="furniture-image service-image-item bg-image col-md-6"></div>
    </div>
    <div class="service-item row">
      <div class="support-image service-image-item bg-image col-md-6"></div>
      <div class="content-right col-md-6">
        <h3 class="content-title">近隣トラブル解決相談サポート</h3>
        <div class="content-text" v-if="!isToggleSeeMore">
          <p>
            ちょっとした住まいのトラブルは早期解決が重要!
            <br />
            どこにでもある「ちょっとした行き違い」
            <br />
            時間とともにこじれて、大きな事件に繋がることが増えています。
          </p>
          <p>
            ● 元警察官による会員専用の相談サービス
            <br />
            「誰に、どう相談したらいいの?」
            <br />
            「警察や行政に協力して貰うには?」
            <br />
            ① 状況に合わせた防犯アドバイス
            <br />
            ② 解決に向けた具体的な対策提案
            <br />
          </p>
          <span class="btn-view text-primary font-italic cursor-pointer" @click="onShowContent">
            続きを読む
            <b-icon-chevron-double-down></b-icon-chevron-double-down>
          </span>
        </div>

        <div class="content-text" v-else>
          <p>
            ちょっとした住まいのトラブルは早期解決が重要!
            <br />
            どこにでもある「ちょっとした行き違い」
            <br />
            時間とともにこじれて、大きな事件に繋がることが増えています。
          </p>
          <p>
            ● 元警察官による会員専用の相談サービス
            <br />
            「誰に、どう相談したらいいの?」
            <br />
            「警察や行政に協力して貰うには?」
            <br />
            ① 状況に合わせた防犯アドバイス
            <br />
            ② 解決に向けた具体的な対策提案
            <br />
            ③ 各行政機関との連携段取りガイド
            <br />
            ④
            生活再建費用の一部支援不法侵入やストーカー被害・悪質かつ継続的な嫌がらせ等で以後の生活を安全に過ごすため、必要であると認められるもの。
            <br />
            転居他、対策にかかる費用を支援(上限10万円、対象費用に制限有)する場合あり。
            <br />
            ※警察に被害届が出されている、緊急性が高い等の条件があります。
            <br />
            保険ではないので、対象内容や金額はサービス提供会社の判断によります。
          </p>
          <p>
            ● 生活再建費用の一部負担
            <br />
            不法侵入やストーカー被害・悪質かつ継続的な嫌がらせ等で。
            <br />
            以後の生活を安全に過ごすため転居が必要であると認められるもの。
            <br />
            転居費用を10万円まで支給（仲介料や礼金）。
            <br />
            ※警察に被害届が出されている等の条件があります。
          </p>
          <span class="btn-view text-primary font-italic cursor-pointer" @click="onShowContent">
            折りたたむ
            <b-icon-chevron-double-up></b-icon-chevron-double-up>
          </span>
        </div>
      </div>
    </div>
    <div class="service-item row">
      <div class="content-left col-md-6">
        <h3 class="content-title">迷惑行為などの記録用アプリ無償提供</h3>
        <div class="content-text">
          <p>
            証拠があればトラブルの早期解決に大きく役立ちます。
            <br />
            お手持ちのスマートフォンで使える証拠記録用アプリを無償で提供します。
          </p>
          <p>
            ● 画像・音声・位置情報・音量（目安デシベル値）が同時に記録できる。
            <br />
            ● アプリインストールと初回設定後は「2タップ」ですぐ記録開始。
            <br />
            ● 保存した証拠画像を第三者へ送信可能です。（動画ファイルとして送信）
          </p>
        </div>
      </div>
      <div class="provide-image service-image-item bg-image col-md-6"></div>
    </div>
    <div class="service-item row">
      <div class="water-image service-image-item bg-image col-md-6"></div>
      <div class="content-right col-md-6">
        <h3 class="content-title">災害、断水対策ウォーターサーバー無償貸出＆初回タンク無料</h3>
        <div class="content-text">
          <p>ウォーターサーバーシェア№１の「プレミアムウォーター」がお得に利用できます。</p>
          <p>
            ● 会員特典として12ℓボトル×２本（4,233円相当）が無料で提供されます。
            <br />
            ● 事務手数料（3,300円）が免除となります。
          </p>
          <p>
            ※注意※
            <br />
            利用期間が2年未満で解約となる場合は解約料1万円が発生します。
          </p>
        </div>
      </div>
    </div>
    <div class="service-item item-last row">
      <div class="content-left col-md-6">
        <h3 class="content-title">賃貸住宅トラブル対応をする弁護士情報の提供</h3>
        <div class="content-text">
          <p>
            トラブル解決のための最終手段は弁護士による法的対応になります。
            <br />
            弁護士であればだれでも賃貸住宅に関連するトラブル案件を引き受けられるわけではありません。
            <br />
            賃貸住宅トラブルの事件の引き受け可能な弁護士の連絡先リストを提供しています。
          </p>
          <p>
            ● 当社が会員や弁護士から「紹介料」等の費用を頂くことはありません。
            <br />
            ● 弁護士に事件解決の依頼される場合、各弁護士が定める所定の費用が発生します。
          </p>
        </div>
      </div>
      <div class="law-image service-image-item bg-image col-md-6"></div>
    </div>
    <button
      class="btn-contact btn-warning text-white"
      @click="$emit('scroll-to-register')"
      v-if="isAuthenticated"
    >
      今すぐ会員登録
    </button>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { BIconChevronDoubleDown, BIconChevronDoubleUp } from 'bootstrap-vue';

export default defineComponent({
  name: 'PageService',
  components: {
    BIconChevronDoubleDown,
    BIconChevronDoubleUp,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const isToggleSeeMore = ref<boolean>(false);
    const onShowContent = () => {
      isToggleSeeMore.value = !isToggleSeeMore.value;
    };
    return {
      isToggleSeeMore,
      onShowContent,
    };
  },
});
</script>
