import PageTop from '@/pages/top/View.vue';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { memberAuth, otherAuth, paymentAuth, qrCodeAuth } from '@/middleware/index';
import cookie from '@/utils/cookie';
import { TOKEN_KEY } from '@/constants';

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'top',
    component: PageTop,
  },
  {
    path: '/qrlogin',
    name: 'QR Login',
    beforeEnter: (to, from, next) => qrCodeAuth(to, from, next),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "*" */ '@/components/other/NotFound.vue'),
  },
  {
    path: '/tokusho',
    name: 'TokushoPage',
    component: () => import(/* webpackChunkName: "TokushoPage" */ '@/pages/other/Tokusho.vue'),
  },
  {
    path: '/payment',
    name: 'ProcessPayment',
    component: () => import(/* webpackChunkName: "Payment" */ '@/pages/payment/Process.vue'),
    beforeEnter: (to, from, next) => paymentAuth(to, from, next),
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: () =>
      import(/* webpackChunkName: "Payment Success" */ '@/pages/payment/Success.vue'),
    beforeEnter: (to, from, next) => paymentAuth(to, from, next),
  },
  {
    path: '/member/faq',
    name: 'HelpQ&A',
    component: () => import(/* webpackChunkName: "HelpFAQ" */ '@/pages/faq/View.vue'),
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import(/* webpackChunkName: "member" */ '../pages/member/View.vue'),
    beforeEnter: (to, from, next) => memberAuth(to, from, next),
    redirect: () => {
      const isMail = cookie.get('isMail') === 'true';
      if (isMail) return '/member/profile';
      return '/member/home';
    },
    children: [
      {
        path: '/member/home',
        name: 'HomeMember',
        component: () => import(/* webpackChunkName: "Home-member" */ '@/pages/member/Home.vue'),
      },
      {
        path: '/member/qa',
        name: 'HelpFAQMember',
        component: () =>
          import(/* webpackChunkName: "HelpFAQ-member" */ '@/pages/member/HelpFAQ.vue'),
      },
      {
        path: 'rental-appliance',
        name: 'RentalAppliance',
        component: () =>
          import(/* webpackChunkName: "RentalAppliance" */ '@/pages/member/RentalAppliance.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'trouble-recording',
        name: 'TroubleRecording',
        component: () =>
          import(/* webpackChunkName: "TroubleRecording" */ '@/pages/member/TroubleRecording.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'profile',
        name: 'ProfileMember',
        component: () =>
          import(/* webpackChunkName: "profile-member" */ '@/pages/member/ProfileUser.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'neighbor-trouble',
        name: 'NeighborTroubleMember',
        component: () =>
          import(/* webpackChunkName: "Neighbor Trouble" */ '@/pages/member/NeighborTrouble.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'water-trouble',
        name: 'WaterTroubleMember',
        component: () => import(/* webpackChunkName: "Water Trouble" */ '@/pages/member/Water.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'attorney-information',
        name: 'AttorneyInformationMember',
        component: () =>
          import(
            /* webpackChunkName: "Attorney information" */ '@/pages/member/AttorneyInformation.vue'
          ),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'inquiry',
        name: 'InquiryMember',
        component: () => import(/* webpackChunkName: "Inquiry" */ '@/pages/member/Inquiry.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'payment-history',
        name: 'PaymentHistory',
        component: () =>
          import(/* webpackChunkName: "Payment History" */ '@/pages/member/PaymentHistory.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'cancel-membership',
        name: 'CancelMembership',
        component: () =>
          import(/* webpackChunkName: "Cancel Membership" */ '@/pages/member/CancelMembership.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
    ],
  },
  {
    path: '/agency',
    name: 'agency',
    component: () => import(/* webpackChunkName: "agency" */ '../pages/agency/View.vue'),
    beforeEnter: (to, from, next) => otherAuth(to, from, next),
    redirect: () => {
      return '/agency/pamphlet';
    },
    children: [
      {
        path: 'pamphlet',
        name: 'PamphletQR',
        component: () =>
          import(/* webpackChunkName: "users-agency" */ '@/pages/agency/PamphletQR.vue'),
        beforeEnter: (to, from, next) => otherAuth(to, from, next),
      },
      {
        path: 'profile',
        name: 'ProfileAgency',
        component: () =>
          import(/* webpackChunkName: "users-agency" */ '@/pages/agency//ProfileUser.vue'),
        beforeEnter: (to, from, next) => otherAuth(to, from, next),
      },
      {
        path: '/agency/qa',
        name: 'HelpFAQAgency',
        component: () =>
          import(/* webpackChunkName: "HelpFAQAgency" */ '@/pages/member/HelpFAQ.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/auth/UserLogin.vue'),
    meta: {
      disableIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => otherAuth(to, from, next),
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import(/* webpackChunkName: "admin-login" */ '@/components/admin/Login.vue'),
    meta: {
      disableIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => otherAuth(to, from, next),
  },
  {
    path: '/resend-email',
    name: 'resendEmail',
    component: () =>
      import(/* webpackChunkName: "resendEmail" */ '../components/auth/VerifyEmail.vue'),
    meta: {
      disableIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => otherAuth(to, from, next),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(/* webpackChunkName: "forgot-password" */ '@/components/password/Forgot.vue'),
    meta: {
      disableIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => otherAuth(to, from, next),
  },
  {
    path: '/verify-forgot-password',
    name: 'CheckEmail',
    component: () =>
      import(/* webpackChunkName: "verify-password" */ '@/components/password/VerifyEmail.vue'),
    meta: {
      disableIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => otherAuth(to, from, next),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(/* webpackChunkName: "set-new-password" */ '@/components/password/Reset.vue'),
    meta: {
      disableIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => otherAuth(to, from, next),
  },
  {
    path: '/change-password-success',
    name: 'ResetPasswordSuccess',
    component: () =>
      import(
        /* webpackChunkName: "change-success-password" */ '@/components/password/ChangeSuccess.vue'
      ),
    meta: {
      disableIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => otherAuth(to, from, next),
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin-page" */ '@/pages/admin/View.vue'),
    redirect: () => {
      const isAuthenticated = cookie.get(TOKEN_KEY) !== null;
      if (!isAuthenticated) return '/admin/login';
      return '/admin/dashboard';
    },
    children: [
      {
        path: 'dashboard',
        name: 'DashboardAdmin',
        component: () =>
          import(/* webpackChunkName: "DashboardAdmin" */ '@/pages/admin/Dashboard.vue'),
        beforeEnter: (to, from, next) => otherAuth(to, from, next),
      },
      {
        path: 'lawyer',
        name: 'LawyerInformation',
        component: () =>
          import(/* webpackChunkName: "LawyerInformation" */ '@/pages/admin/LawyerInformation.vue'),
        beforeEnter: (to, from, next) => otherAuth(to, from, next),
      },
      {
        path: 'inquiry',
        name: 'InquiryManagement',
        component: () =>
          import(/* webpackChunkName: "InquiryManagement" */ '@/pages/admin/InquiryManagement.vue'),
        beforeEnter: (to, from, next) => otherAuth(to, from, next),
      },
      {
        path: 'agency-management',
        name: 'AgencyManagement',
        component: () =>
          import(/* webpackChunkName: "AgencyManagement" */ '@/pages/admin/AgencyManagement.vue'),
        beforeEnter: (to, from, next) => otherAuth(to, from, next),
      },
      {
        path: 'user-management',
        name: 'UsersManagement',
        component: () =>
          import(
            /* webpackChunkName: "admin-user-management" */ '@/pages/admin/UserManagement.vue'
          ),
        beforeEnter: (to, from, next) => otherAuth(to, from, next),
      },
      {
        path: 'flyer',
        name: 'FlyerManagement',
        component: () =>
          import(/* webpackChunkName: "FlyerManagement" */ '@/pages/admin/FlyerManagement.vue'),
        beforeEnter: (to, from, next) => otherAuth(to, from, next),
      },
      {
        path: '/admin/qa',
        name: 'HelpFAQAdmin',
        component: () =>
          import(/* webpackChunkName: "HelpFAQAdmin" */ '@/pages/member/HelpFAQ.vue'),
      },
      {
        path: '/admin/create-coupon',
        name: 'CreateCouponAdmin',
        component: () =>
          import(/* webpackChunkName: "CreateCouponAdmin" */ '@/pages/admin/CreateCoupon.vue'),
      },
    ],
  },
  {
    path: '/confirm-email',
    name: 'EmailConfirm',
    component: () =>
      import(/* webpackChunkName: "confirm-email" */ '@/components/auth/EmailConfirm.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
