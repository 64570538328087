<template>
  <header class="page-header position-sticky">
    <b-navbar toggleable="lg" type="light" variant="light" class="nav-header bg-white">
      <div class="btn-navToogle d-flex align-content-center">
        <b-navbar-toggle target="nav-collapse" @click="onHandleClickNav" class="btn-nav">
          <b-icon-x-lg v-if="isCheckToggleButton"></b-icon-x-lg>
        </b-navbar-toggle>
      </div>

      <b-collapse is-nav class="nav-item">
        <slot name="navbar" />
      </b-collapse>
      <template>
        <template v-if="isAuthenticated">
          <div class="d-flex align-items-center">
            <p class="mb-0 d-flex">
              <span class="display-name">{{ user.fullName }}</span>
              <span class="sub-name">様</span>
            </p>
            <b-dropdown
              size="sm"
              variant="light"
              toggle-class="text-decoration-none"
              right
              no-caret
            >
              <template #button-content>
                <b-icon-person-circle></b-icon-person-circle>
              </template>
              <b-dropdown-item v-if="checkProfileRoute()" @click="handleClickProfile">
                <b-icon-person></b-icon-person>
                プロファイル
              </b-dropdown-item>
              <b-dropdown-item @click="handleLogout">
                <b-icon-box-arrow-left></b-icon-box-arrow-left>
                ログアウト
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <router-link v-else to="/login" size="sm" class="btn btn-header my-2 my-sm-0 btn-primary">
          会員ログイン
        </router-link>
      </template>
    </b-navbar>
    <b-collapse id="nav-collapse" is-nav v-if="isCheckToggleButton">
      <b-navbar-nav class="nav-item-btn bg-white" @click="onHandleClickNav">
        <slot name="navbar" />
      </b-navbar-nav>
    </b-collapse>
  </header>
</template>

<script lang="ts">
import { useAuth } from '@/composables/useAuth';
import { useDevice } from '@/composables/useDevice';
import { useUser } from '@/composables/useUser';
import {
  BCollapse,
  BDropdown,
  BDropdownItem,
  BIconBoxArrowLeft,
  BIconPersonCircle,
  BIconXLg,
  BNavbar,
  BNavbarNav,
  BNavbarToggle,
  BIconPerson,
} from 'bootstrap-vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router/composables';

export default defineComponent({
  name: 'PageHeader',
  components: {
    BNavbarToggle,
    BNavbar,
    BCollapse,
    BNavbarNav,
    BIconXLg,
    BIconPersonCircle,
    BDropdown,
    BDropdownItem,
    BIconBoxArrowLeft,
    BIconPerson,
  },
  setup() {
    const { isMobile, isIpad } = useDevice();
    const isCheckToggleButton = ref<boolean>(false);
    const { user, isAuthenticated } = useUser();
    const { handleLogout, handleRedirectProfile } = useAuth();
    const route = useRoute();

    const onHandleClickNav = () => {
      isCheckToggleButton.value = !isCheckToggleButton.value;
    };

    const handleUpdateToggleButton = () => {
      isCheckToggleButton.value = false;
    };

    const handleClickProfile = () => {
      handleRedirectProfile(user.value);
    };

    const checkProfileRoute = () => {
      const routesArr = ['admin', 'agency', 'member'];
      if (!routesArr.some((v) => route.path.includes(v))) return true;
      return;
    };

    onMounted(() => window.addEventListener('resize', handleUpdateToggleButton));

    return {
      isCheckToggleButton,
      isMobile,
      isIpad,
      user,
      isAuthenticated,
      handleClickProfile,
      checkProfileRoute,
      onHandleClickNav,
      handleLogout,
    };
  },
});
</script>
