import axiosIns from '@/service/httpClient';
import Api from './api';
import { SignInBody } from '@/models/auth.model';
class AuthApi extends Api {
  constructor() {
    super('auth');
  }

  login(resource: SignInBody) {
    return axiosIns({
      url: `/${this.uri}/login`,
      method: 'post',
      data: resource,
    });
  }
}
export { AuthApi as default };
