<template>
  <section class="section-banner d-flex bg-image justify-content-center align-items-center">
    <div class="banner-content position-relative">
      <h2 class="banner-title text-white text-center">
        生活の「もしも」に備える
        <br />
        サポートを
      </h2>
      <p class="banner-text text-white text-center">
        不意に起きる災害や生活トラブルへの備えに！
        <br />
        完全会員制「住環境サポート」が、あなたに起きるかもしれない「もしも」をしっかりサポートし、安心・安全な暮らしのお手伝いをします。
      </p>
    </div>
    <span class="banner-arrow-down text-white position-absolute">↓詳細を見る↓</span>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageHome',
});
</script>
