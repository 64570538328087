import UserApi from '@/api/userApi';
import { TOKEN_KEY, USER_KEY } from '@/constants';
import { UserInterface } from '@/models/user.model';
import cookie from '@/utils/cookie';
import { computed, reactive } from 'vue';
import { useLoading } from './useLoading';
import { localStorage } from '@/utils/localStorage';
import { encodeData, decodeData } from '@/utils/encode';

const state = reactive<{ user: UserInterface }>({
  user: localStorage.getStorageItem(USER_KEY)
    ? decodeData(localStorage.getStorageItem(USER_KEY))
    : {
        id: null,
        email: '',
        displayName: '',
        password: '',
        firstName: '',
        lastName: '',
        fullName: '',
        accountStatus: null,
        dayOfBirth: '',
        gender: null,
        phoneNumber: '',
        userType: null,
        address: '',
        createdAt: '',
        updatedAt: '',
        registerDate: '',
        expirationDate: '',
        isPaid: false,
        profileImage: '',
      },
});

export const useUser = () => {
  const userApi = new UserApi();
  const isAuthenticated = cookie.get(TOKEN_KEY) !== null;
  const { $loading, isLoading } = useLoading();

  const setUser = (user: UserInterface) => {
    state.user = user;
    localStorage.setStorageItem(USER_KEY, encodeData(user));
  };

  const getUser = computed(() => state.user);

  const fetchUser = async () => {
    try {
      const res = await userApi.getUser();

      if (res) {
        setUser(res.data);
      }
      return Promise.resolve(res);
    } catch (error: any) {
      if (error) {
        cookie.delete(TOKEN_KEY);
        localStorage.removeStorageItem(USER_KEY);
        location.reload();
      }
      return Promise.reject(error);
    }
  };

  const unsubscribe = async () => {
    try {
      $loading.start();
      const res = await userApi.unsubscribe({});
      return res;
    } catch (error: any) {
      return error;
    } finally {
      $loading.start();
    }
  };

  return {
    fetchUser,
    user: getUser,
    isAuthenticated,
    isLoading,
    unsubscribe,
  };
};
