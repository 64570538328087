export const localStorage = {
  getStorageItem(name: string) {
    if (!name) return;
    const value = window.localStorage.getItem(name);

    return value && value !== 'undefined' && JSON.parse(value);
  },

  setStorageItem(name: string, value: any) {
    if (!name) return;

    return window.localStorage.setItem(name, JSON.stringify(value));
  },

  removeStorageItem(name: string) {
    if (!name) return;

    return window.localStorage.removeItem(name);
  },

  clearStorage() {
    window.localStorage.clear();
  },
};
