import { NavigationGuard, START_LOCATION } from 'vue-router';
import { useUser } from '@/composables/useUser';
import { TOKEN_KEY } from '@/constants';
import cookie from '@/utils/cookie';
import { UserType, AccountStatus } from '@/models/user.model';

export const paymentAuth: NavigationGuard = async (to, from, next) => {
  const isAuthenticated = cookie.get(TOKEN_KEY) !== null;
  const { user } = useUser();
  const { userType } = user.value;

  if (isAuthenticated) {
    if (userType === UserType.User) {
      if (to.path === '/payment') {
        if (user.value.accountStatus === AccountStatus.Active) {
          if (from.path === '/member/payment-history' || from === START_LOCATION) {
            next();
          } else {
            next('/');
          }
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next('/');
    }
  } else {
    next('login');
  }
};
