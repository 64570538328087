export const validatorPassword = (password: string) => {
  const regExp = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!.@#$%^&*()_+{}|~\\[\]:";`'<=>?,/-]{8,20}$/;

  const validPassword = regExp.test(password);
  return validPassword;
};

export const validatorEmail = (email: string) => {
  const regExp = /^[a-zA-Z0-9]+([_.-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;

  const validEmail = regExp.test(email);
  return validEmail;
};

export const validatorPhoneNumber = (phone: string) => {
  //Phone number (no dash, 9 or 12 characters)
  const regExp = /^[0-9]{9,12}$/;

  const validPhone = regExp.test(phone);
  return validPhone;
};

export const validatorFieldEmpty = (text: string) => {
  if (text) return true;
  return;
};

export const validatorKatakana = (text: string) => {
  //Japanese katakana full and halfwidth characters
  const regExp = /[\u30A0-\u30FF\uFF66-\uFF9F]/;

  const validKatagana = regExp.test(text);
  return validKatagana;
};

export const checkKatakana = (event: any) => {
  if (!validatorKatakana(event.target.value)) {
    event.preventDefault();
  } else {
    return true;
  }
};

export const validatorOnlyNumber = (event: any) => {
  const keyCode = event.keyCode ? event.keyCode : event.which;
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    event.preventDefault();
  }
};
