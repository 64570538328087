const cookie = {
  get(name: string) {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  },

  set(name: string, value: any, days = 7) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toUTCString()}`;
  },

  delete(name: string) {
    this.set(name, '', -1);
  },
};

export default cookie;
