export const sectionStorage = {
  getSectionItem(name: string) {
    if (!name) return;
    const value = window.sessionStorage.getItem(name);

    return value && value !== 'undefined' && JSON.parse(value);
  },

  setSectionItem(name: string, value: any) {
    if (!name) return;

    return window.sessionStorage.setItem(name, JSON.stringify(value));
  },

  removeSectionItem(name: string) {
    if (!name) return;

    return window.sessionStorage.removeItem(name);
  },
};
