import { NavigationGuard } from 'vue-router';
import { useUser } from '@/composables/useUser';
import { TOKEN_KEY, USER_KEY } from '@/constants';
import cookie from '@/utils/cookie';
import { UserType } from '@/models/user.model';
import { localStorage } from '@/utils/localStorage';

export const otherAuth: NavigationGuard = async (to, from, next) => {
  const isAuthenticated = cookie.get(TOKEN_KEY) !== null;
  const { user } = useUser();
  const { userType } = user.value;

  if (to.matched.some((record) => record.meta.disableIfLoggedIn)) {
    if (to.path === '/forgot-password' && to?.query.isApp === 'true') {
      if (isAuthenticated) {
        cookie.delete(TOKEN_KEY);
        localStorage.removeStorageItem(USER_KEY);
      }
      next();
    } else {
      if (isAuthenticated) {
        next('/');
      } else {
        next();
      }
    }
  } else {
    if (isAuthenticated && userType !== UserType.User) {
      next();
    } else {
      next('/login');
    }
  }
};
