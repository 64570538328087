import CryptoJS from 'crypto-js';
const SECRET = 'trouble';

export const encodeData = (data: any) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), 'pass').toString();

export const decodeData = (data: any) => {
  const decrypted = CryptoJS.AES.decrypt(data, 'pass');
  const dataR = CryptoJS.enc.Utf8.stringify(decrypted);

  return JSON.parse(dataR);
};

export const encByHex = (plainText: any) => {
  const b64 = CryptoJS.AES.encrypt(plainText, SECRET).toString();
  const e64 = CryptoJS.enc.Base64.parse(b64);
  const eHex = e64.toString(CryptoJS.enc.Hex);

  return eHex;
};

export const encToPayment = (data: any) => {
  const jsonData = JSON.stringify(data);

  return CryptoJS.enc.Hex.stringify(CryptoJS.enc.Utf8.parse(jsonData));
};

export const decFromPayment = (encodedData: string) => {
  const parsedData = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Hex.parse(encodedData));
  const jsonData = JSON.parse(parsedData);

  return jsonData;
};
