import { NavigationGuard } from 'vue-router';

export const qrCodeAuth: NavigationGuard = async (to, from, next) => {
  const { os } = to?.query;

  if (os) {
    if (os === 'ios') {
      window.location.replace(
        'https://apps.apple.com/us/app/%E3%83%88%E3%83%A9%E3%83%96%E3%83%AB%E8%A8%98%E9%8C%B2/id6472387638'
      );
    }
    if (os === 'android') {
      window.location.replace('https://play.google.com/store/apps/details?id=com.trouble_resoving_system');
    }
  } else {
    next('/');
  }
};
