<template>
  <form class="form-register" @submit.prevent>
    <h2 class="register-title text-center" v-if="typeFormSignUp">会員登録</h2>
    <h5 class="signup-title text-center" v-if="typeFormSignUp">
      下記の項目に必要事項を入力して、登録ボタンを押してください。
    </h5>
    <b-skeleton-wrapper
      v-if="!isCheckTypeForm.typeSuccess"
      :loading="isLoading && (typeFormUpdateMember || typeFormUpdateAgency)"
    >
      <template #loading>
        <b-card>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
        </b-card>
      </template>
      <div v-if="typeFormUpdateAgency" class="form-group d-flex w-100">
        <label class="form-label">会社名（必須）</label>
        <div class="form-input-full">
          <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
            {{ userData.companyName.value }}
          </label>
          <input
            ref="firstInputAgency"
            v-if="isCheckTypeForm.typeInput"
            autocomplete="off"
            type="text"
            class="form-control"
            v-model.trim="userData.companyName.value"
            @blur="
              validateFields(
                userData.companyName,
                EventType.Blur,
                INPUT_LIST.companyName.name,
                null
              )
            "
            @focus="
              validateFields(
                userData.companyName,
                EventType.Focus,
                INPUT_LIST.companyName.name,
                null
              )
            "
            :class="{ 'input-warning': userData.companyName.isError }"
            @input="
              () =>
                $emit(
                  'input-change',
                  propsUser && (propsUser.companyName === userData.companyName.value ? false : true)
                )
            "
            @keydown.enter="focusNextElement"
          />
          <p v-if="userData.companyName.isError" class="msg-danger">
            {{ userData.companyName.msg }}
          </p>
        </div>
      </div>
      <div v-if="typeFormUpdateAgency" class="form-group d-flex w-100">
        <label class="form-label">支社/支店名（必須）</label>
        <div class="form-input-full">
          <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
            {{ userData.branchName.value }}
          </label>
          <input
            v-if="isCheckTypeForm.typeInput"
            autocomplete="off"
            type="text"
            class="form-control"
            v-model.trim="userData.branchName.value"
            @blur="
              validateFields(userData.branchName, EventType.Blur, INPUT_LIST.branchName.name, null)
            "
            @focus="
              validateFields(userData.branchName, EventType.Focus, INPUT_LIST.branchName.name, null)
            "
            :class="{ 'input-warning': userData.branchName.isError }"
            @input="
              () =>
                $emit(
                  'input-change',
                  propsUser && (propsUser.branchName === userData.branchName.value ? false : true)
                )
            "
            @keydown.enter="focusNextElement"
            @keydown.up="focusNextElement"
          />
          <p v-if="userData.branchName.isError" class="msg-danger">
            {{ userData.branchName.msg }}
          </p>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">
          {{ typeFormUpdateAgency ? '担当者（必須）' : '名前（必須）' }}
        </label>
        <div class="form-group-input d-flex">
          <div class="form-input ml-0">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.firstName.value }}
            </label>
            <input
              ref="firstInputMember"
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.firstName.value"
              :class="{ 'input-warning': userData.firstName.isError }"
              @blur="
                validateFields(userData.firstName, EventType.Blur, INPUT_LIST.firstName.name, null)
              "
              @focus="
                validateFields(userData.firstName, EventType.Focus, INPUT_LIST.firstName.name, null)
              "
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser && (propsUser.firstName === userData.firstName.value ? false : true)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
            <p v-if="userData.firstName.isError" class="msg-danger">
              {{ userData.firstName.msg }}
            </p>
          </div>
          <div class="form-input mr-0">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.lastName.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.lastName.value"
              :class="{ 'input-warning': userData.lastName.isError }"
              @blur="
                validateFields(userData.lastName, EventType.Blur, INPUT_LIST.lastName.name, null)
              "
              @focus="
                validateFields(userData.lastName, EventType.Focus, INPUT_LIST.lastName.name, null)
              "
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser && (propsUser.lastName === userData.lastName.value ? false : true)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
            <p v-if="userData.lastName.isError" class="msg-danger">
              {{ userData.lastName.msg }}
            </p>
          </div>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">
          {{ typeFormUpdateAgency ? '担当者名フリガナ（必須）' : '名前フリガナ（必須）' }}
        </label>
        <div class="form-group-input d-flex">
          <div class="form-input ml-0">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.furiganaFirstName.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.furiganaFirstName.value"
              :class="{ 'input-warning': userData.furiganaFirstName.isError }"
              @keypress="checkKatakana($event)"
              @blur="
                validateFields(
                  userData.furiganaFirstName,
                  EventType.Blur,
                  INPUT_LIST.furiganaFirstName.name,
                  null
                )
              "
              @focus="
                validateFields(
                  userData.furiganaFirstName,
                  EventType.Focus,
                  INPUT_LIST.furiganaFirstName.name,
                  null
                )
              "
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser &&
                      (propsUser.furiganaFirstName === userData.furiganaFirstName.value
                        ? false
                        : true)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
            <p v-if="userData.furiganaFirstName.isError" class="msg-danger">
              {{ userData.furiganaFirstName.msg }}
            </p>
          </div>
          <div class="form-input mr-0">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.furiganaLastName.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.furiganaLastName.value"
              :class="{ 'input-warning': userData.furiganaLastName.isError }"
              @keypress="checkKatakana($event)"
              @blur="
                validateFields(
                  userData.furiganaLastName,
                  EventType.Blur,
                  INPUT_LIST.furiganaLastName.name,
                  null
                )
              "
              @focus="
                validateFields(
                  userData.furiganaLastName,
                  EventType.Focus,
                  INPUT_LIST.furiganaLastName.name,
                  null
                )
              "
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser &&
                      (propsUser.furiganaLastName === userData.furiganaLastName.value
                        ? false
                        : true)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
            <p v-if="userData.furiganaLastName.isError" class="msg-danger">
              {{ userData.furiganaLastName.msg }}
            </p>
          </div>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">メールアドレス（必須）</label>
        <div class="form-input-full">
          <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
            {{ userData.email.value }}
          </label>
          <input
            v-if="isCheckTypeForm.typeInput"
            autocomplete="off"
            type="text"
            class="form-control"
            @blur="validateFields(userData.email, EventType.Blur, INPUT_LIST.email.name, null)"
            @focus="validateFields(userData.email, EventType.Focus, INPUT_LIST.email.name, null)"
            @keydown.space="$event.preventDefault()"
            v-model.trim="userData.email.value"
            :class="{
              'input-warning': userData.email.isError,
            }"
            @input="
              () =>
                $emit(
                  'input-change',
                  propsUser && (propsUser.email === userData.email.value ? false : true)
                )
            "
            @change="isEmailChange = true"
            @keydown.enter="focusNextElement"
            @keydown.up="focusNextElement"
          />
          <p v-if="userData.email.isError" class="msg-danger">
            {{ userData.email.msg }}
          </p>
        </div>
      </div>
      <div class="form-group d-flex w-100" v-if="!typeFormSignUp">
        <label class="form-label">現在のパスワード</label>
        <div class="form-input-full">
          <div class="position-relative">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.oldPassword.value ? '*********' : '' }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              v-model.trim="userData.oldPassword.value"
              :type="!isShowPassword.oldPassword ? 'password' : 'text'"
              class="form-control"
              :class="{
                'input-warning': userData.oldPassword.isError,
                disabled: isCheckTypeForm.typeConfirm,
                'border-0 pl-0': isCheckTypeForm.typeConfirm,
              }"
              autocomplete="off"
              @input="
                () =>
                  $emit('input-change', propsUser && (userData.oldPassword.value ? true : false))
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
              @blur="
                onHandleResetPassword(
                  userData.oldPassword,
                  userData.password,
                  userData.confirmPassword
                )
              "
            />
            <button
              v-if="userData.oldPassword.value"
              @click="isShowPassword.oldPassword = !isShowPassword.oldPassword"
              tabindex="-1"
              type="button"
              class="btn-show-password"
              :class="{ 'd-none': isCheckTypeForm.typeConfirm }"
            >
              <b-icon-eye v-if="isShowPassword.oldPassword" class="p-0"></b-icon-eye>
              <b-icon-eye-slash v-else class="p-0"></b-icon-eye-slash>
            </button>
          </div>
          <p v-if="userData.oldPassword.isError" class="msg-danger">
            {{ userData.oldPassword.msg }}
          </p>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">
          {{ typeFormSignUp ? 'パスワード（必須）' : '新しいパスワード ' }}
        </label>
        <div class="form-input-full">
          <div class="position-relative">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.password.value ? '*********' : '' }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              v-model.trim="userData.password.value"
              :type="!isShowPassword.password ? 'password' : 'text'"
              class="form-control"
              :class="{
                'input-warning': userData.password.isError,
                disabled: isCheckTypeForm.typeConfirm,
                'border-0 pl-0': isCheckTypeForm.typeConfirm,
              }"
              autocomplete="new-password"
              @blur="
                onHandleValidatePassword(
                  userData.password,
                  EventType.Blur,
                  INPUT_LIST.password.name,
                  userData
                )
              "
              @focus="
                validateFields(userData.password, EventType.Focus, INPUT_LIST.password.name, null)
              "
              @keydown.space="$event.preventDefault()"
              @input="
                () => $emit('input-change', propsUser && (userData.password.value ? true : false))
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
              :disabled="userData.oldPassword ? (userData.oldPassword.value ? false : true) : false"
            />
            <button
              v-if="userData.password.value"
              type="button"
              class="btn-show-password"
              :class="{ 'd-none': isCheckTypeForm.typeConfirm }"
              tabindex="-1"
              @click="isShowPassword.password = !isShowPassword.password"
            >
              <b-icon-eye v-if="isShowPassword.password" class="p-0"></b-icon-eye>
              <b-icon-eye-slash v-else class="p-0"></b-icon-eye-slash>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">
          {{ typeFormSignUp ? 'パスワードの確認（必須）' : '新しいパスワードを再入力' }}
        </label>
        <div class="form-input-full">
          <div class="position-relative">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.confirmPassword.value ? '*********' : '' }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              v-model.trim="userData.confirmPassword.value"
              :type="!isShowPassword.confirmPassword ? 'password' : 'text'"
              class="form-control"
              :class="{
                'input-warning': userData.confirmPassword.isError,
                disabled: isCheckTypeForm.typeConfirm,
                'border-0 pl-0': isCheckTypeForm.typeConfirm,
              }"
              autocomplete="new-password"
              @blur="
                onHandleValidatePassword(
                  userData.confirmPassword,
                  EventType.Blur,
                  INPUT_LIST.confirmPassword.name,
                  userData
                )
              "
              @focus="
                validateFields(
                  userData.confirmPassword,
                  EventType.Focus,
                  INPUT_LIST.confirmPassword.name,
                  userData
                )
              "
              @keydown.space="$event.preventDefault()"
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser && (userData.confirmPassword.value ? true : false)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
              :disabled="userData.oldPassword ? (userData.oldPassword.value ? false : true) : false"
            />
            <button
              v-if="userData.confirmPassword.value"
              type="button"
              class="btn-show-password"
              :class="{ 'd-none': isCheckTypeForm.typeConfirm }"
              tabindex="-1"
              @click="isShowPassword.confirmPassword = !isShowPassword.confirmPassword"
            >
              <b-icon-eye v-if="isShowPassword.confirmPassword" class="p-0"></b-icon-eye>
              <b-icon-eye-slash v-else class="p-0"></b-icon-eye-slash>
            </button>
          </div>
          <p v-if="userData.confirmPassword.isError" class="msg-danger">
            {{ userData.confirmPassword.msg }}
          </p>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <p class="msg-danger">
          ※パスワードは半角8桁以上20桁以下で、英数字を1文字以上入れてください。
        </p>
      </div>
      <div
        class="form-group d-flex w-100 justify-content-between align-items-center"
        v-if="!typeFormUpdateAgency"
      >
        <label class="form-label">性別 (必須)</label>
        <div class="form-input-full">
          <b-form-radio-group
            v-model="userData.gender.value"
            :options="SEX_TYPE_OPTION"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            :disabled="isCheckTypeForm.typeConfirm"
            @input="
              () =>
                $emit(
                  'input-change',
                  propsUser && (propsUser.gender === userData.gender.value ? false : true)
                )
            "
          ></b-form-radio-group>
        </div>
      </div>
      <div
        class="form-group d-flex w-100 justify-content-between align-items-center"
        v-if="!typeFormUpdateAgency"
      >
        <label class="form-label">生年月日 (必須)</label>
        <div class="form-input-full">
          <p v-if="isCheckTypeForm.typeConfirm" class="form-label">
            {{ userData.yearBirthday.value }}年/{{
              userData.monthBirthday.value.toString().padStart(2, '0')
            }}月/{{ userData.dayBirthday.value.toString().padStart(2, '0') }}日
          </p>
          <div class="d-flex group-select" v-if="isCheckTypeForm.typeInput">
            <v-select
              :class="{ 'input-warning': userData.yearBirthday.isError }"
              v-model="userData.yearBirthday.value"
              :reduce="(year) => year.value"
              :options="YEAR_BIRTHDAY_OPTION"
              @input="handleValidateBirthday(userData.yearBirthday)"
              :get-option-label="(year) => year.text"
              class="select-year"
            >
              <template v-slot:no-options>
                {{ MessageClient.MSG011 }}
              </template>
            </v-select>
            <v-select
              :class="{ 'input-warning': userData.monthBirthday.isError }"
              class="mx-2 select-month"
              v-model="userData.monthBirthday.value"
              :reduce="(month) => month.value"
              :options="MONTH_OPTION"
              @input="handleValidateBirthday(userData.monthBirthday)"
              :get-option-label="(month) => month.text"
            >
              <template v-slot:no-options>
                {{ MessageClient.MSG011 }}
              </template>
            </v-select>
            <v-select
              :class="{ 'input-warning': userData.dayBirthday.isError }"
              v-model="userData.dayBirthday.value"
              :reduce="(date) => date.value"
              :options="optionDay"
              @input="handleValidateBirthday(userData.dayBirthday)"
              :get-option-label="(date) => date.text"
              :disabled="!isCheckDayBirthday"
              class="select-day"
            >
              <template v-slot:no-options>
                {{ MessageClient.MSG011 }}
              </template>
            </v-select>
          </div>
          <p
            v-if="
              userData.yearBirthday.isError ||
              userData.monthBirthday.isError ||
              userData.dayBirthday.isError
            "
            class="msg-danger"
          >
            生年月日 を入力してください。
          </p>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">電話番号（必須）</label>
        <div class="form-group-input d-flex">
          <div class="form-input ml-0">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.phoneNumber.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              @blur="
                validateFields(userData.phoneNumber, EventType.Blur, INPUT_LIST.phone.name, null)
              "
              @focus="
                validateFields(userData.phoneNumber, EventType.Focus, INPUT_LIST.phone.name, null)
              "
              v-model.trim="userData.phoneNumber.value"
              :class="{
                'input-warning': userData.phoneNumber.isError,
              }"
              @keypress="validatorOnlyNumber($event)"
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser &&
                      (propsUser.phoneNumber === userData.phoneNumber.value ? false : true)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
            <p v-if="userData.phoneNumber.isError" class="msg-danger">
              {{ userData.phoneNumber.msg }}
            </p>
          </div>
          <div class="form-input mr-0" v-if="isCheckTypeForm.typeInput">
            <p class="form-control form-require mb-0">※ハイフンなし</p>
          </div>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">郵便番号（必須）</label>
        <div class="form-group-input d-flex">
          <div class="form-input ml-0">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.zipCode.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              @blur="
                validateFields(userData.zipCode, EventType.Blur, INPUT_LIST.zipCode.name, userData)
              "
              @focus="
                validateFields(userData.zipCode, EventType.Focus, INPUT_LIST.zipCode.name, userData)
              "
              v-model.trim="userData.zipCode.value"
              :class="{ 'input-warning': userData.zipCode.isError }"
              @keypress="validatorOnlyNumber($event)"
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser && (propsUser.zipCode === userData.zipCode.value ? false : true)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
            <p v-if="userData.zipCode.isError" class="msg-danger">
              {{ userData.zipCode.msg }}
            </p>
          </div>
          <div class="form-input mr-0" v-if="isCheckTypeForm.typeInput">
            <p class="form-control form-require mb-0">※ハイフンなし</p>
          </div>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">都道府県（必須）</label>
        <div class="form-group-input d-flex">
          <div class="form-input ml-0">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.prefecture.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.prefecture.value"
              @blur="
                validateFields(
                  userData.prefecture,
                  EventType.Blur,
                  INPUT_LIST.prefecture.name,
                  null
                )
              "
              @focus="
                validateFields(
                  userData.prefecture,
                  EventType.Focus,
                  INPUT_LIST.prefecture.name,
                  null
                )
              "
              :class="{ 'input-warning': userData.prefecture.isError }"
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser && (propsUser.prefecture === userData.prefecture.value ? false : true)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
            <p v-if="userData.prefecture.isError" class="msg-danger">
              {{ userData.prefecture.msg }}
            </p>
          </div>
          <div class="form-input mr-0"></div>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">市区町村（必須）</label>
        <div class="form-input-full">
          <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
            {{ userData.city.value }}
          </label>
          <input
            v-if="isCheckTypeForm.typeInput"
            autocomplete="off"
            type="text"
            class="form-control"
            v-model.trim="userData.city.value"
            @blur="validateFields(userData.city, EventType.Blur, INPUT_LIST.city.name, null)"
            @focus="validateFields(userData.city, EventType.Focus, INPUT_LIST.city.name, null)"
            :class="{ 'input-warning': userData.city.isError }"
            @input="
              () =>
                $emit(
                  'input-change',
                  propsUser && (propsUser.city === userData.city.value ? false : true)
                )
            "
            @keydown.enter="focusNextElement"
            @keydown.up="focusNextElement"
          />
          <p v-if="userData.city.isError" class="msg-danger">
            {{ userData.city.msg }}
          </p>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">以降の住所（必須）</label>
        <div class="form-input-full">
          <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
            {{ userData.subsequentAddress.value }}
          </label>
          <input
            v-if="isCheckTypeForm.typeInput"
            autocomplete="off"
            type="text"
            class="form-control"
            v-model.trim="userData.subsequentAddress.value"
            @blur="
              validateFields(
                userData.subsequentAddress,
                EventType.Blur,
                INPUT_LIST.subsequentAddress.name,
                null
              )
            "
            @focus="
              validateFields(
                userData.subsequentAddress,
                EventType.Focus,
                INPUT_LIST.subsequentAddress.name,
                null
              )
            "
            @input="
              () =>
                $emit(
                  'input-change',
                  propsUser &&
                    (propsUser.subsequentAddress === userData.subsequentAddress.value
                      ? false
                      : true)
                )
            "
            :class="{ 'input-warning': userData.subsequentAddress.isError }"
            @keydown.enter="focusNextElement"
            @keydown.up="focusNextElement"
          />
          <p v-if="userData.subsequentAddress.isError" class="msg-danger">
            {{ userData.subsequentAddress.msg }}
          </p>
        </div>
      </div>
      <div class="form-group d-flex w-100">
        <label class="form-label">建物名・部屋番号</label>
        <div class="form-input-full">
          <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
            {{ userData.buildingNameAndRoomNumber.value }}
          </label>
          <input
            v-if="isCheckTypeForm.typeInput"
            autocomplete="off"
            type="text"
            class="form-control"
            v-model.trim="userData.buildingNameAndRoomNumber.value"
            @input="
              () =>
                $emit(
                  'input-change',
                  propsUser &&
                    (propsUser.buildingNameAndRoomNumber ===
                    userData.buildingNameAndRoomNumber.value
                      ? false
                      : true)
                )
            "
            @keydown.enter="focusNextElement"
            @keydown.up="focusNextElement"
          />
        </div>
      </div>
      <div v-if="!typeFormUpdateAgency" class="form-group d-flex w-100">
        <label class="form-label">代理店コード（必須）</label>
        <div class="form-input-full">
          <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
            {{ userData.agencyCode.value }}
          </label>
          <input
            v-if="isCheckTypeForm.typeInput"
            autocomplete="off"
            type="text"
            class="form-control"
            v-model.trim="userData.agencyCode.value"
            @blur="
              validateFields(
                userData.agencyCode,
                EventType.Blur,
                INPUT_LIST.agencyCode.name,
                agenciesData
              )
            "
            @focus="
              validateFields(
                userData.agencyCode,
                EventType.Focus,
                INPUT_LIST.agencyCode.name,
                agenciesData
              )
            "
            :class="{ 'input-warning': userData.agencyCode.isError }"
            @input="
              () =>
                $emit(
                  'input-change',
                  propsUser && (propsUser.agencyCode === userData.agencyCode.value ? false : true)
                )
            "
            @keydown.enter="focusNextElement"
            @keydown.up="focusNextElement"
          />
          <p v-if="userData.agencyCode.isError" class="msg-danger">
            {{ userData.agencyCode.msg }}
          </p>
        </div>
      </div>
      <h4 class="text-center title-rules" v-if="typeFormSignUp">会員規約</h4>
      <div class="content-title" v-if="typeFormSignUp" ref="areaRule">
        <p>会員規約</p>
        <p>
          この会員規約（以下「規約」といいます）は、不動産研究会株式会社（以下「提供者」といいます）と、提供者が提供する会員サービス（以下「サービス」といいます）の会員との間で成立する契約を定めるものです。本規約に同意することで、会員（以下「会員」といいます）は、以下の内容に同意するものとします。
        </p>
        <p>1. サービスの提供</p>
        <p class="mb-0">
          1.1
          提供者は、会員に対して以下のサービスを提供します。サービスの内容、範囲、利用条件は、提供者の裁量に基づいて定められます。
        </p>
        <p class="mb-0">①生活トラブルを記録するためのスマートフォンアプリの提供</p>
        <p class="mb-0">②生活トラブル発生時の電話相談の受付（委託先：ヴァンガードスミス社）</p>
        <p class="mb-0">③ウォーターサーバー本体の無償貸与（水および水の宅配は別途料金必要）</p>
        <p class="mb-0">
          ④提携先の家具・家電レンタル事業者のレンタル料金を、市価より５％割り引いて利用できる権利（提携先：ｅレンタルｎｅｔ）
        </p>
        <p>
          ⑤提供者が独自に調査した賃貸住宅入居者のトラブル相談に応じられる弁護士事務所の連絡先リストの提供（相談料や依頼料は別途必要）
        </p>
        <p class="mb-0">1.2 サービスの提供方法、会費およびその支払い方法は下記の通りとします。</p>
        <p class="mb-0">
          ①生活トラブル記録のアプリケーションは会員が持つスマートフォンにダウンロードして利用する
        </p>
        <p class="mb-0">
          ②生活トライブルの電話相談は、提供者のウェブサイトに掲示される専用電話番号へ電話をすることで提供する
        </p>
        <p class="mb-0">
          ③ウォーターサーバー本体の無償貸与は、提供者のウェブサイトからウォーターサーバー提供会社へ定期宅配契約の申込を行うことで提供する。
        </p>
        <p class="mb-0">
          ④家具・家電レンタルの割引は、提携先レンタル事業者の申込時に割引が適用される様にするための特定コードを発行することで提供する。
        </p>
        <p>
          ⑤弁護士事務所連絡先リストは、提供者のウェブサイト上にて会員のみが閲覧できるページに掲載することで提供する。
        </p>
        <p class="mb-0">２）会費：月額400円（消費税別）</p>
        <p class="mb-0 ml-4">物価や最低賃金等の変動により、改定されることがあります。</p>
        <p class="mb-0 ml-4">改定は、改定日の1か月以上前に会員へメール通知した上で行います。</p>
        <p class="mb-0">３）会費支払：会員の指定するクレジットカードによって支払うものとします。</p>
        <p class="mb-0">４）会費は入会手続きを行った翌月1日から発生します。</p>
        <p class="mb-0 ml-4">会費計算は1か月単位を最小単位とし、日割り計算は行いません。</p>
        <p class="ml-4">また、一度支払われた会費は如何なる理由があっても返金致しません。</p>
        <p>2. 会員資格</p>
        <p>
          2.1 会員になれるのは18才以上の個人で、自身で契約行為ができる方になります。
          サービスの利用を希望する個人は、提供者の定める申込み手続きを完了させた日の翌月1日より正式に会員となります。
        </p>
        <p>
          2.2
          会員は、提供者に対して真実かつ正確な情報を提供することに同意します。提供者は、提供された情報が虚偽である場合、会員資格を取り消す権利を有します。
        </p>
        <p>３. 契約期間と解約</p>
        <p>
          3.1
          会員は、サービスの契約期間内に解約を希望する場合は、提供者の指定するウェブサイト上で解約手続きを行う必要があります。
        </p>
        <p>3.2 解約は、解約手続きが完了した日の翌月末日に成立します。解約料はかかりません。</p>
        <p>3.3 会費の未払い期間が1ヵ月を超えた場合、提供者の判断で強制退会となります。</p>
        <p>4. 利用規約の変更</p>
        <p>
          提供者は、事前に通知することで、本規約の内容を変更または修正する権利を有します。変更後の規約は、会員に通知された時点から効力を有するものとします。
        </p>
        <p>5. 免責事項</p>
        <p>
          提供者は、サービスの提供に関連する事故、損害、不具合に対して一切の責任を負わないものとします。
        </p>
        <p>6. 個人情報の取り扱い</p>
        <p>
          提供者は、会員の個人情報を適切に取り扱うための措置を講じます。詳細については、提供者のプライバシーポリシーをご確認ください。
        </p>
        <p>7. 準拠法と管轄裁判所</p>
        <p>
          本規約は、[所在地の法律または条約]に基づいて解釈されるものとし、紛争の解決に関しては、東京地方裁判所を専属的な管轄裁判所とします。
        </p>
        <p>【プライバシーポリシー】</p>
        <p>1. 収集する情報</p>
        <p>当ウェブサイトの利用に関連して、以下の種類の情報を収集することがあります:</p>
        <p>
          個人情報: お名前、メールアドレス、連絡先情報、生年月日など、特定の個人を識別できる情報。
        </p>
        <p>非個人情報: ブラウジング履歴、デバイス情報、Cookieなど、個人を特定しない情報。</p>
        <p>2. 収集方法</p>
        <p class="mb-0">個人情報は、以下の方法で収集することがあります</p>
        <p>ユーザーが当ウェブサイトを訪れ、情報を提供する場合。</p>
        <p>3. 情報の使用</p>
        <p class="mb-0">収集した情報は、以下の目的で使用されることがあります:</p>
        <p class="mb-0">会員のサービスの提供、改善、保守。</p>
        <p class="mb-0">法的要件の遵守。</p>
        <p class="mb-0">会員への連絡。</p>
        <p>統計データの収集および分析。</p>
        <p>4. 情報の共有</p>
        <p class="mb-0">個人情報は、以下の場合に限り、第三者と共有されることがあります:</p>
        <p class="mb-0">ユーザーの明示的な同意がある場合。</p>
        <p class="mb-0">法的要件に従うため。</p>
        <p class="mb-0">
          サービス提供のために協力するパートナー企業と情報を共有する必要がある場合。
        </p>
        <p>5. セキュリティ</p>
        <p>
          収集した情報を適切に保護するために、技術的および組織的な対策を講じています。ただし、インターネット上の情報の送信には完全なセキュリティが保証されないことを理解してください。
        </p>
        <p>6. Cookieの使用</p>
        <p>当ウェブサイトでは、Cookieを使用することがあります。</p>
        <p>7. 未成年者のプライバシー</p>
        <p>
          当ウェブサイトは、18才以上の個人へのサービス提供を目的としており、未成年者に関する情報を意図的に収集しません。
        </p>
        <p>8. プライバシーポリシーの変更</p>
        <p>
          当ウェブサイトのプライバシーポリシーは変更されることがあり、変更がある場合は本ページに掲載します。
        </p>
      </div>
      <div class="form-check d-flex align-items-center pl-0" v-if="typeFormSignUp">
        <input
          class="form-check-input position-relative ml-0 mt-0 pl-0"
          type="checkbox"
          id="checkbox"
          :disabled="isEnableCheckbox"
          v-model="isCheckTerm"
        />
        <label class="form-check-label" for="checkbox">会員規約に同意します（必須）</label>
      </div>
      <template v-if="typeFormUpdateMember">
        <div class="info-card">
          <p>●クレジットカード情報</p>
          <p class="m-0">
            クレジットカード情報は決済代行会社のゼウスによって管理を行っており、
            <br />
            当サイトでは保存いたしません。
          </p>
        </div>
        <div class="form-group d-flex w-100">
          <label class="form-label">カード番号（必須）</label>
          <div class="form-input-full">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.cardNumber.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.cardNumber.value"
              @blur="
                validateFields(
                  userData.cardNumber,
                  EventType.Blur,
                  INPUT_LIST.numberCard.name,
                  null
                )
              "
              @focus="
                validateFields(
                  userData.cardNumber,
                  EventType.Focus,
                  INPUT_LIST.numberCard.name,
                  null
                )
              "
              :class="{ 'input-warning': userData.cardNumber.isError }"
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser &&
                      (propsUser.cardNumber === userData.cardNumber.value.slice(-4) ? false : true)
                  )
              "
              @keypress="validatorOnlyNumber($event)"
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
            <p v-if="userData.cardNumber.isError" class="msg-danger">
              {{ userData.cardNumber.msg }}
            </p>
          </div>
        </div>
        <div class="form-group d-flex w-100">
          <label class="form-label">カード名義</label>
          <div class="form-input-full">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.cardName.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.cardName.value"
              @input="
                () =>
                  $emit(
                    'input-change',
                    propsUser && (propsUser.cardName === userData.cardName.value ? false : true)
                  )
              "
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
            />
          </div>
        </div>
        <div class="form-group d-flex w-100 justify-content-end">
          <div class="form-group-input d-flex card-number align-items-start">
            <div class="form-input ml-0">
              <label class="form-label w-100 mb-2">有効期限</label>
              <label class="form-label w-100" v-if="isCheckTypeForm.typeConfirm">
                {{ userData.cardExpirationDate.value }}
              </label>

              <input
                v-if="isCheckTypeForm.typeInput"
                autocomplete="none"
                type="text"
                class="form-control text-center"
                v-model.trim="userData.cardExpirationDate.value"
                @input="
                  () =>
                    $emit(
                      'input-change',
                      propsUser && (userData.cardExpirationDate.value ? true : false)
                    )
                "
                placeholder="MM / YY"
                maxlength="5"
                @keypress="onHandleFormatDate($event)"
                @keydown.enter="focusNextElement"
                @keydown.up="focusNextElement"
              />
              <p v-if="userData.cardExpirationDate.isError" class="msg-danger">
                {{ userData.cardExpirationDate.msg }}
              </p>
            </div>
            <div class="form-input mr-0">
              <label class="form-label w-100 mb-2">セキュリティコード</label>
              <label class="form-label w-100" v-if="isCheckTypeForm.typeConfirm">
                {{ userData.securityCode.value && '****' }}
              </label>
              <input
                v-if="isCheckTypeForm.typeInput"
                autocomplete="new-password"
                type="password"
                class="form-control"
                maxlength="4"
                v-model.trim="userData.securityCode.value"
                @input="
                  () =>
                    $emit('input-change', propsUser && (userData.securityCode.value ? true : false))
                "
                @keypress="validatorOnlyNumber($event)"
                @keydown.up="focusNextElement"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-if="typeFormUpdateAgency">
        <div class="info-card">
          <p>●銀行口座情報</p>
          <p class="m-0">代理店報酬受け取り銀行口座をご登録ください。</p>
        </div>
        <div class="form-group d-flex w-100">
          <label class="form-label">銀行名</label>
          <div class="form-input-full">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.bankName.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.bankName.value"
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
              disabled
            />
          </div>
        </div>
        <div class="form-group d-flex w-100">
          <label class="form-label">支店名</label>
          <div class="form-input-full">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.bankBranchName.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.bankBranchName.value"
              @keydown.enter="focusNextElement"
              @keydown.up="focusNextElement"
              disabled
            />
          </div>
        </div>
        <div class="form-group form-group-radio d-flex w-100">
          <label class="form-label">口座種別</label>
          <div class="form-check-radios d-flex flex-row align-items-center">
            <b-form-radio-group
              v-model="userData.bankAccountType.value"
              :options="OPTION_TYPE_ACCOUNT"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              disabled
            ></b-form-radio-group>
          </div>
        </div>
        <div class="form-group d-flex w-100">
          <label class="form-label">口座名義（カタカナ）</label>
          <div class="form-input-full">
            <label class="form-label" v-if="isCheckTypeForm.typeConfirm">
              {{ userData.bankAccountName.value }}
            </label>
            <input
              v-if="isCheckTypeForm.typeInput"
              autocomplete="off"
              type="text"
              class="form-control"
              v-model.trim="userData.bankAccountName.value"
              @keypress="checkKatakana($event)"
              @keydown.up="focusNextElement"
              disabled
            />
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>
    <b-overlay
      v-if="isCheckTypeForm.typeInput && !isCheckTypeForm.typeSuccess"
      rounded
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block overlays btn-container"
      :show="isLoading"
    >
      <button
        type="submit"
        class="d-block btn-warning text-center text-white"
        @click="handleConfirm"
        :disabled="!isValidForm || isLoading || !isCheckChangeNumberCard"
      >
        {{ nameButton }}
      </button>
    </b-overlay>
    <div
      class="d-flex justify-content-between btn-group btn-group-container"
      v-if="isCheckTypeForm.typeConfirm || isCheckTypeForm.typeSuccess"
    >
      <button
        class="btn-back btn-white text-black"
        :class="{ 'type-success': isCheckTypeForm.typeSuccess }"
        @click="handleBack"
        v-if="isCheckTypeForm.typeConfirm || isCheckTypeForm.typeSuccess"
      >
        戻 る
      </button>
      <b-overlay
        v-if="isCheckTypeForm.typeConfirm"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-block overlay"
        :show="isLoading"
      >
        <button class="btn-submit d-block btn-warning text-white" @click="handleSubmit">
          更 新
        </button>
      </b-overlay>
    </div>
  </form>
</template>

<script lang="ts">
import { EventType } from '@/models/helpers.mode';
import { AgencyInterface, BankAccountType, INPUT_LIST } from '@/models/user.model';
import { MessageClient } from '@/shared/msg';
import { validateEmptyField, validateFields } from '@/utils/validate';
import { checkKatakana, validatorOnlyNumber } from '@/utils/validator';
import {
  BCard,
  BIconEye,
  BIconEyeSlash,
  BOverlay,
  BSkeleton,
  BSkeletonWrapper,
  BFormRadioGroup,
} from 'bootstrap-vue';
import { PropType, computed, onMounted, reactive, ref, watch, nextTick } from 'vue';
import { useRoute } from 'vue-router/composables';
import { getMonth, getYear, focusNextElement } from '@/utils/functions';
import { onHandelTop } from '@/utils/functions';
import {
  SEX_TYPE_OPTION,
  MONTH_OPTION,
  YEAR_BIRTHDAY_OPTION,
  OPTION_TYPE_ACCOUNT,
} from '@/shared/filterSelect';
import { getDayOptions } from '@/utils/functions';
import { DATE_FORMAT } from '@/constants/index';
import moment from 'moment';

export default {
  name: 'SignUp',
  components: {
    BOverlay,
    BIconEye,
    BIconEyeSlash,
    BSkeleton,
    BSkeletonWrapper,
    BCard,
    BFormRadioGroup,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    agenciesData: {
      type: Array as PropType<AgencyInterface[]>,
      default: () => [],
    },
    nameButton: {
      default: '確 認',
      type: String,
    },
    typeFormSignUp: {
      default: false,
      type: Boolean,
    },
    typeFormUpdateMember: {
      default: false,
      type: Boolean,
    },
    typeFormUpdateAgency: {
      default: false,
      type: Boolean,
    },
  },
  setup(props: any, { emit }: any) {
    const route = useRoute();
    const agencyCodeQuery = ref(route.query.code || null);
    const areaRule = ref<any>(null);
    const firstInputMember = ref<any>(null);
    const firstInputAgency = ref<any>(null);
    const isCheckTypeForm = reactive({
      typeInput: true,
      typeConfirm: false,
      typeSuccess: false,
    });
    const propsUser = computed(() => props.user);
    const isCheckTerm = ref<boolean>(props.user ? true : false);
    const isShowPassword = reactive({
      oldPassword: false,
      password: false,
      confirmPassword: false,
    });
    const userData = reactive({
      lastName: {
        name: 'lastName',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.lastName.text,
      },
      firstName: {
        name: 'firstName',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.firstName.text,
      },
      furiganaFirstName: {
        name: 'furiganaFirstName',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.furiganaFirstName.text,
      },
      furiganaLastName: {
        name: 'furiganaLastName',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.furiganaLastName.text,
      },
      email: {
        name: 'email',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.email.text,
      },
      gender: {
        name: 'gender',
        isError: false,
        msg: '',
        value: null,
        title: INPUT_LIST.gender.text,
      },
      yearBirthday: {
        name: 'yearBirthday',
        isError: false,
        msg: '',
        value: null,
        title: INPUT_LIST.dateBirthday.text,
      },
      monthBirthday: {
        name: 'monthBirthday',
        isError: false,
        msg: '',
        value: null,
        title: INPUT_LIST.dateBirthday.text,
      },
      dayBirthday: {
        name: 'dayBirthday',
        isError: false,
        msg: '',
        value: null,
        title: INPUT_LIST.dateBirthday.text,
      },
      phoneNumber: {
        name: 'phoneNumber',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.phone.text,
      },
      zipCode: {
        name: 'zipCode',
        isError: false,
        isFocusing: false,
        msg: '',
        value: '',
        title: INPUT_LIST.zipCode.text,
      },
      city: {
        name: 'city',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.city.text,
      },
      prefecture: {
        name: 'prefecture',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.prefecture.text,
      },
      subsequentAddress: {
        name: 'subsequentAddress',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.subsequentAddress.text,
      },
      buildingNameAndRoomNumber: {
        name: 'buildingNameAndRoomNumber',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.buildingNameAndRoomNumber.text,
      },
      ...(!props.typeFormUpdateAgency && {
        agencyCode: {
          name: 'agencyCode',
          isError: false,
          msg: '',
          value: agencyCodeQuery.value || '',
          title: INPUT_LIST.agencyCode.text,
        },
      }),
      password: {
        name: 'password',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.password.text,
      },
      confirmPassword: {
        name: 'confirmPassword',
        isError: false,
        msg: '',
        value: '',
        title: INPUT_LIST.confirmPassword.text,
      },
      ...(!props.typeFormSignUp && {
        oldPassword: {
          name: 'oldPassword',
          isError: false,
          msg: '',
          value: '',
        },
      }),
      ...(props.typeFormUpdateMember && {
        cardNumber: {
          name: 'cardNumber',
          isError: false,
          msg: '',
          value: '',
          title: INPUT_LIST.numberCard.text,
        },
        cardName: {
          name: 'cardName',
          isError: false,
          msg: '',
          value: '',
          title: INPUT_LIST.holderCard.text,
        },
        cardExpirationDate: {
          name: 'cardExpirationDate',
          isError: false,
          msg: '',
          value: '',
          data: '',
          title: INPUT_LIST.expirationDate.text,
        },
        securityCode: {
          name: 'securityCode',
          isError: false,
          msg: '',
          value: '',
          title: INPUT_LIST.securityCode.text,
        },
      }),
      ...(props.typeFormUpdateAgency && {
        companyName: {
          name: 'companyName',
          isError: false,
          msg: '',
          value: '',
          title: INPUT_LIST.companyName.text,
        },
        branchName: {
          name: 'branchName',
          isError: false,
          msg: '',
          value: '',
          title: INPUT_LIST.branchName.text,
        },
        bankName: {
          name: 'bankName',
          isError: false,
          msg: '',
          value: '',
          title: INPUT_LIST.bankName.text,
        },
        bankBranchName: {
          name: 'bankBranchName',
          isError: false,
          msg: '',
          value: '',
          title: INPUT_LIST.bankBranchName.text,
        },
        bankAccountType: {
          name: 'bankAccountType',
          isError: false,
          msg: '',
          value: null,
          title: INPUT_LIST.bankAccountType.text,
        },
        bankAccountName: {
          name: 'bankAccountName',
          isError: false,
          msg: '',
          value: '',
          title: INPUT_LIST.bankAccountName.text,
        },
      }),
    });

    const optionDay = computed(() =>
      getDayOptions(userData.yearBirthday.value, userData.monthBirthday.value)
    );

    const isCheckDayBirthday = computed(
      () => !!userData.monthBirthday.value && !!userData.yearBirthday.value
    );
    const isEmailChange = ref(false);
    const fieldsNotRequired = computed(() => [
      'bankName',
      'bankBranchName',
      'bankAccountType',
      'bankAccountName',
      'cardName',
      'expirationDate',
      'securityCode',
      'cardExpirationDate',
      'buildingNameAndRoomNumber',
      ...(props.typeFormSignUp
        ? []
        : userData.oldPassword.value
        ? []
        : ['oldPassword', 'password', 'confirmPassword']),
      ...(props.typeFormUpdateAgency
        ? ['dayBirthday', 'monthBirthday', 'yearBirthday', 'gender']
        : []),
    ]);

    const isValidForm = computed(
      () =>
        Object.values(userData).every(
          (obj: any) =>
            obj.isError === false &&
            (fieldsNotRequired.value.includes(obj.name) ||
              (!fieldsNotRequired.value.includes(obj.name) && obj.value))
        ) && isCheckTerm.value
    );

    const handleSignUp = async () => {
      const isPassValidateAgency = await !!validateFields(
        userData.agencyCode,
        EventType.Blur,
        INPUT_LIST.agencyCode.name,
        props.agenciesData
      );
      if (userData.zipCode.isFocusing) {
        await validateFields(userData.zipCode, EventType.Blur, INPUT_LIST.zipCode.name, userData);
      }

      if (isValidForm.value && isPassValidateAgency) {
        let body: any = [];
        Object.values(userData).map((key: any) => {
          if (key.name === 'bankAccountType') {
            body[key.name] = key.value;
          } else if (key.name === 'agencyCode') {
            body[key.name] = key.value.toString();
          } else {
            body[key.name] = key.value;
          }
        });

        emit(
          'handle-sign-up',
          Object.assign(
            {},
            {
              ...body,
            }
          ),
          () => {
            isCheckTypeForm.typeConfirm = false;
            isCheckTypeForm.typeSuccess = true;
          }
        );
      }
    };

    const handleValidateAgency = (value: any) => {
      emit('input-change', true);
      if (!value) {
        validateEmptyField(userData.agencyCode);
      } else {
        userData.agencyCode.isError = false;
        userData.agencyCode.msg = '';
      }
    };

    const handleValidateBirthday = (item: any) => {
      emit('input-change', true);
      if (!item.value) {
        validateEmptyField(item);
      } else {
        item.isError = false;
        item.msg = '';
      }
    };

    const getUserDetails = () => {
      let userDetails: any = { ...userData };
      let userEditProfile: any = { ...propsUser.value };
      Object.values(userData).map((key: any) => {
        if (key.name === 'bankAccountType' && !userEditProfile[key.name]) {
          userDetails[key.name].value = userEditProfile[key.name];
        } else if (!userEditProfile[key.name]) {
          userDetails[key.name].value = '';
        } else {
          userDetails[key.name].value = userEditProfile[key.name];
        }
        if (key.name === 'cardExpirationDate') {
          userDetails[key.name].value = '';
        }
        if (key.name === 'cardNumber') {
          userDetails[key.name].value =
            userEditProfile.cardNumber && `****-****-****-${userEditProfile.cardNumber}`;
        }
        userDetails[key.name].isError = false;
      });
      if (userEditProfile.dateOfBirth) {
        const dateBirthday: any = moment(userEditProfile.dateOfBirth)
          .format(DATE_FORMAT)
          .split('/');
        userDetails.yearBirthday.value = Number(dateBirthday[0]);
        userDetails.monthBirthday.value = Number(dateBirthday[1]);
        userDetails.dayBirthday.value = Number(dateBirthday[2]);
      }

      return Object.assign(userData, userDetails);
    };

    const onHandleFormatDate = (event: any) => {
      validatorOnlyNumber(event);
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (userData.cardExpirationDate.value.length === 2 && keyCode !== 32) {
        userData.cardExpirationDate.value = `${userData.cardExpirationDate.value}/`;
      }
    };

    const handleConfirm = () => {
      emit('input-change', false);
      if (props.typeFormSignUp) {
        handleSignUp();
      } else {
        isCheckTypeForm.typeInput = false;
        isCheckTypeForm.typeConfirm = true;
      }
    };
    const handleSubmit = () => {
      if (isEmailChange.value) {
        emit('update-email', {
          isUpdateEmail: true,
          newEmail: userData.email.value,
        });
      }
      handleSignUp();
    };
    const handleBack = () => {
      emit('update-dirty', false);
      isCheckTypeForm.typeInput = true;
      isCheckTypeForm.typeConfirm = false;
      isCheckTypeForm.typeSuccess = false;
    };

    const onHandleValidatePassword = (
      input: any,
      eventType: string,
      name: string,
      dataNeedValidate: any
    ) => {
      const isCheckOldPassword = userData.oldPassword
        ? !!userData.oldPassword.value
        : !!input.value;
      if ((isCheckOldPassword && !props.typeFormSignUp) || props.typeFormSignUp) {
        validateFields(input, eventType, name, dataNeedValidate);
      }
    };

    const isCheckChangeNumberCard = computed(() =>
      userData.cardNumber
        ? props.user.cardNumber === userData.cardNumber.value.slice(-4)
          ? true
          : !!userData.cardExpirationDate.value && !!userData.securityCode.value
        : true
    );
    const isEnableCheckbox = ref<boolean>(true);

    const onHandleEnableCheckbox = () => {
      if (
        areaRule.value.scrollTop + areaRule.value.clientHeight >=
        areaRule.value.scrollHeight - 100
      ) {
        isEnableCheckbox.value = false;
      }
    };

    const onHandleResetPassword = (oldPassword: any, password: any, confirmPassword: any) => {
      if (!oldPassword.value) {
        password.value = '';
        password.isError = false;
        confirmPassword.value = '';
        confirmPassword.isError = false;
      }
    };

    watch(isCheckTypeForm, () => {
      emit('update-check-type-form', isCheckTypeForm);
    });

    watch(
      [() => userData.yearBirthday.value, () => userData.monthBirthday.value],
      () => {
        const checkBirthday = optionDay.value.find(
          (x: any) => x.value === userData.dayBirthday.value
        );
        if (!checkBirthday) {
          userData.dayBirthday.value = null;
        }
      },
      { deep: true }
    );

    watch(route, () => {
      handleBack();
      getUserDetails();
    });

    watch(
      [propsUser],
      () => {
        getUserDetails();
      },
      { deep: true }
    );

    onMounted(() => {
      if (props.user) {
        getUserDetails();
      }
      if (props.typeFormUpdateMember) {
        onHandelTop();
        setTimeout(() => {
          nextTick(() => {
            firstInputMember?.value?.focus();
            emit('input-change', false);
          });
        }, 500);
      }
      if (props.typeFormUpdateAgency) {
        nextTick(() => {
          firstInputAgency.value.focus();
        });
      }
      if (props.typeFormSignUp) {
        areaRule.value.addEventListener('scroll', onHandleEnableCheckbox);
      }
    });

    return {
      areaRule,
      isEnableCheckbox,
      firstInputMember,
      isCheckTypeForm,
      propsUser,
      isShowPassword,
      MessageClient,
      INPUT_LIST,
      userData,
      EventType,
      isValidForm,
      isCheckTerm,
      checkKatakana,
      handleSignUp,
      validateFields,
      handleValidateAgency,
      validatorOnlyNumber,
      onHandleFormatDate,
      handleSubmit,
      handleBack,
      handleConfirm,
      isEmailChange,
      BankAccountType,
      getMonth,
      getYear,
      onHandleValidatePassword,
      focusNextElement,
      firstInputAgency,
      isCheckChangeNumberCard,
      SEX_TYPE_OPTION,
      MONTH_OPTION,
      YEAR_BIRTHDAY_OPTION,
      handleValidateBirthday,
      optionDay,
      isCheckDayBirthday,
      OPTION_TYPE_ACCOUNT,
      onHandleResetPassword,
    };
  },
};
</script>
