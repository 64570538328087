<template>
  <div class="top-page">
    <Header>
      <template #navbar>
        <ul class="header-list d-flex p-0 m-0 align-items-center">
          <li
            class="header-list-item d-flex align-items-center"
            :class="{ 'item-active': isCheckElementTop }"
          >
            <router-link
              class="text-black active font-weight-bold"
              :class="{ 'text-active': isCheckElementTop }"
              :to="handleChangeHeaderRoute"
              @click.native="handleScrollElement('top')"
            >
              トップ
            </router-link>
          </li>
          <li
            class="header-list-item d-flex align-items-center"
            :class="{ 'item-active': isCheckElementService }"
          >
            <router-link
              class="text-black active font-weight-bold"
              :class="{ 'text-active': isCheckElementService }"
              :to="handleChangeHeaderRoute"
              @click.native="handleScrollElement('service')"
            >
              サービス内容
            </router-link>
          </li>
          <li
            class="header-list-item d-flex align-items-center"
            :class="{ 'item-active': isCheckElementRegister }"
            v-if="!isAuthenticated"
          >
            <router-link
              class="text-black active font-weight-bold"
              :class="{ 'text-active': isCheckElementRegister }"
              :to="handleChangeHeaderRoute"
              @click.native="handleScrollElement('register')"
            >
              入会申し込み
            </router-link>
          </li>
          <li class="header-list-item d-flex align-items-center">
            <span
              class="text-black active font-weight-bold cursor-pointer"
              @click="handleRedirectToFaq"
            >
              ヘルプ・よくある質問
            </span>
          </li>
        </ul>
      </template>
    </Header>
    <Banner />
    <Service
      @scroll-to-register="handleScrollElement('register')"
      :isAuthenticated="!isAuthenticated"
      ref="serviceElement"
    />
    <section class="section-register">
      <SignUp
        v-if="!isAuthenticated"
        @handle-sign-up="handleSignup"
        :is-loading="isLoading"
        :agencies-data="agenciesData"
        :type-form-sign-up="true"
        ref="formRegister"
      />
      <div class="register-footer-menu">
        <div class="register-footer w-100 h-100 infor d-flex justify-content-start flex-column">
          <p class="mb-4 register-sub-title">賃貸住宅向け住環境サポートサービス produced by</p>
          <img src="../../assets/images/logo-trouble-resolve.webp" class="image-logo" alt="logo" />
        </div>
        <ul class="footer-bottom d-flex justify-content-between w-100 list-unstyled">
          <li>
            <a href="/tokusho" class="register-footer-text text-black">特商法に基づく表記</a>
          </li>
          <li>
            <a
              href="https://estate-labo.jp/privacypolicy"
              class="register-footer-text text-black"
              target="_blank"
            >
              プライバシーポリシー
            </a>
          </li>
          <li>
            <a
              href="https://estate-labo.jp/contact"
              class="register-footer-text text-black"
              target="_blank"
            >
              お問い合わせ
            </a>
          </li>
          <li>
            <a
              href="https://estate-labo.jp"
              class="register-footer-text text-black"
              target="_blank"
            >
              不動産研究会ホームページ
            </a>
          </li>
          <li v-if="!isAuthenticated">
            <a href="/login" class="register-footer-text text-black">代理店ログイン</a>
          </li>
        </ul>
      </div>
    </section>
    <b-toast
      :variant="toastOptions.variant"
      v-model="toastOptions.isShow"
      :title="toastOptions.title"
      :auto-hide-delay="3000"
    >
      {{ toastOptions.message }}
    </b-toast>
    <Footer />
  </div>
</template>

<script lang="ts">
import Footer from '@/layouts/default/Footer.vue';
import Header from '@/layouts/default/Header.vue';
import { defineComponent, onMounted, ref, onBeforeMount, computed } from 'vue';
import Banner from './Banner.vue';
import Service from './Service.vue';
import SignUp from '@/components/auth/SignUp.vue';
import UserApi from '@/api/userApi';
import { UserSignUpInterface, AgencyInterface } from '@/models/user.model';
import { BToast } from 'bootstrap-vue';
import { useToast } from '@/composables/useToast';
import { ToastInterface } from '@/models/helpers.mode';
import { useRouter, useRoute } from 'vue-router/composables';
import { MessageClient } from '@/shared/msg';
import cookie from '@/utils/cookie';
import { EMAIL_KEY, IS_LOGIN_KEY } from '@/constants/index';
import { useLoading } from '@/composables/useLoading';
import { useUser } from '@/composables/useUser';
import { onHandelTop } from '@/utils/functions';
import { sectionStorage } from '@/utils/sectionStorage';

export default defineComponent({
  name: 'PageTop',
  components: {
    Header,
    Banner,
    Service,
    SignUp,
    Footer,
    BToast,
  },

  setup() {
    const UsersApi = new UserApi();
    const { toastOptions, showToast } = useToast();
    const router = useRouter();
    const route = useRoute();
    const formRegister = ref<any>(null);
    const serviceElement = ref<any>(null);
    const agenciesData = ref<Array<AgencyInterface>>([]);
    const { isLoading, $loading } = useLoading();
    const { isAuthenticated } = useUser();

    const handleSignup = async (userData: UserSignUpInterface) => {
      try {
        $loading.start();
        const res = await UsersApi.signUp(userData);
        if (res) {
          cookie.set(EMAIL_KEY, userData.email);
          router.push('/resend-email');
        }
      } catch (error: any) {
        const { message } = error.data;
        const toastOptions: ToastInterface = {
          isShow: true,
          title: MessageClient.MSG006,
          variant: 'danger',
          message,
        };
        showToast(toastOptions);
      } finally {
        $loading.finish();
      }
    };
    const getHeightElement = () => {
      heightService.value = serviceElement.value
        ? serviceElement.value?.$el.children[6].offsetTop
        : 0;
      heightRegister.value = formRegister.value?.$el.offsetTop;
    };
    const heightService: any = ref<number>(0);
    const heightRegister: any = ref<number>(0);
    const { code } = route.query;

    const handleChangeHeaderRoute = computed((): string => {
      if (code) return `/?code=${code}`;
      return '/';
    });

    const handleRedirectToFaq = () => {
      if (code) sectionStorage.setSectionItem('code', code);
      router.push('/member/faq');
    };

    const handleScrollElement = (element: string) => {
      let heightScroll;
      switch (element) {
        case 'top':
          heightScroll = 0;
          break;
        case 'service':
          if (serviceElement.value) {
            heightScroll = heightService.value - 100;
          }
          break;
        case 'register':
          if (formRegister.value) {
            heightScroll = heightRegister.value - 150;
          }
          break;
      }

      window.scrollTo({
        top: heightScroll,
        behavior: 'smooth',
      });
    };

    const fetchAgencies = async () => {
      try {
        const res = await UsersApi.getAllAgencies();
        if (res) {
          agenciesData.value = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const isCheckElementTop = ref<boolean>(false);
    const isCheckElementService = ref<boolean>(false);
    const isCheckElementRegister = ref<boolean>(false);

    const resetActiveHeader = () => {
      isCheckElementTop.value = false;
      isCheckElementService.value = false;
      isCheckElementRegister.value = false;
    };

    const handleScroll = () => {
      let heightService: any;
      let heightRegister: any;
      if (serviceElement.value) {
        heightService = serviceElement.value.$el.children[6].offsetTop;
      }
      if (formRegister.value) {
        heightRegister = formRegister.value.$el.offsetTop;
      }

      if (window.scrollY >= 0 && window.scrollY < heightService - 800) {
        resetActiveHeader();
        isCheckElementTop.value = true;
      } else if (
        serviceElement.value &&
        window.scrollY >= heightService - 800 &&
        (heightRegister ? window.scrollY < heightRegister - 800 : true)
      ) {
        resetActiveHeader();
        isCheckElementService.value = true;
      } else if (
        formRegister.value &&
        window.scrollY >= heightRegister - 800 &&
        window.scrollY < heightRegister + 1000
      ) {
        resetActiveHeader();
        isCheckElementRegister.value = true;
      }
    };

    const update = () => {
      heightService.value = serviceElement.value && serviceElement.value?.$el.children[6].offsetTop;
      heightRegister.value = formRegister.value?.$el.offsetTop;
    };

    onMounted(() => {
      onHandelTop();
      if (cookie.get(IS_LOGIN_KEY)) {
        cookie.delete(IS_LOGIN_KEY);
      }
      fetchAgencies();
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', update);
      if (window.scrollY === 0) {
        isCheckElementTop.value = true;
      }
      getHeightElement();
      if (route.query.section) {
        handleScrollElement('register');
        router.replace({ path: '/' });
      }
    });

    onBeforeMount(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', update);
    });

    return {
      heightService,
      heightRegister,
      isAuthenticated,
      formRegister,
      toastOptions,
      handleSignup,
      isLoading,
      agenciesData,
      serviceElement,
      handleScrollElement,
      isCheckElementTop,
      isCheckElementService,
      isCheckElementRegister,
      handleChangeHeaderRoute,
      handleRedirectToFaq,
    };
  },
});
</script>
