import { reactive } from 'vue';
import { ToastInterface } from '@/models/helpers.mode';

export const useToast = () => {
  const toastOptions = reactive<ToastInterface>({
    isShow: false,
    title: '',
    variant: '',
    message: '',
  });

  const showToast = (options: ToastInterface) => {
    toastOptions.isShow = options.isShow;
    toastOptions.title = options.title;
    toastOptions.variant = options.variant;
    toastOptions.message = options.message;
  };

  return {
    toastOptions,
    showToast,
  };
};
