export enum EventType {
  Blur = 'blur',
  Focus = 'focus',
}

export interface ToastInterface {
  isShow: boolean;
  title: string;
  variant: string;
  message: string;
}
