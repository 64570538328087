export const MessageClient = {
  //Field requires
  MSG001: (field: string) => `${field} を入力してください。`,
  // Format Number Phone Error
  MSG002: '電話番号の形式が正しくありません。',
  // Format Email Error
  MSG003: 'メールの形式が正しくありません。',
  // Format Password Error
  MSG004: 'パスワードの形式が正しくありません。半角8桁以上20桁以下、英数字1文字以上必須',
  // Format ConfirmPassword Error
  MSG005: 'パスワードが一致しません。同じパスワードを入力してください。',
  // Notifications
  MSG006: '通知',
  // There are no records to show
  MSG007: 'データがありません。',
  // Loading
  MSG008: 'ローディング。。。',
  // Format Deleted Successfully
  MSG009: (name: string) => `${name}削除に成功しました。`,
  // Format Updated Successfully
  MSG010: (name: string) => `${name}更新に成功しました。`,
  // Vue-select no option
  MSG011: 'データがありません。',
  // CSV export completed successfully
  MSG012: 'CSVファイルの出力に成功しました。',
  // An error when download CSV file.
  MSG013: 'CSVファイルの出力に失敗しました。もう一度試してください。',
  // Warning when user leaves page with unsaved changes
  MSG014: '情報を保存していません。このページを離れてもよろしいですか。',
  // Success when agency send mail to member
  MSG015: 'メールの送信に成功しました。',
  // Success when sent form data
  MSG016: 'フォームの送信に成功しました。',
  // Error when agency send mail to member
  MSG017: 'メール送信に失敗しました。',
  // Show Dialog when agency send mail to member don't title
  MSG018: 'このメールを送信してもよろしいですか。',
  // CSV import completed successfully
  MSG019: 'CSVファイルの入力に成功しました。',
  // An error when upload CSV file.
  MSG020: 'CSVファイルの入力に失敗しました。もう一度試してください。',
  // PDF import completed successfully
  MSG021: 'PDFファイルの入力に成功しました。',
  // An error when upload PDF file.
  MSG022: 'PDFファイルの入力に失敗しました。もう一度試してください。',
  // Register payment success
  MSG023: 'クレジットカード登録に成功しました。',
  // Payment error
  MSG024: '会員納金に失敗しました。もう一度試してください。',
  //Payment success
  MSG025: '会費納金に成功しました。',
  // Validate number card
  MSG026: '13数字以上入力してください。',
  // Validate Security Code
  MSG027: '3数字以上入力してください。',
  // Validate Current Month
  MSG028: '期限切れのカード有効期限が入力されています。',
  // Validate File Csv
  MSG029: 'CSV入力に失敗しました。もう一度ファイルをチェックしてください。',
  // Check status unpaid for account
  MSG030: 'カード情報が登録されていないため、ステータスをアクティブに変更できません。',
  // Format Updated Successfully
  MSG031: (name: string) => `${name}の更新に成功しました。`,
  // Format Updated Error
  MSG032: (name: string) => `${name}の更新に失敗しました`,
  // Format Create Successfully
  MSG033: (name: string) => `${name}の作成に成功しました。`,
  // Format Create Error
  MSG034: (name: string) => `${name}の作成に失敗しました。`,
  // Format Delete Successfully
  MSG035: (name: string) => `${name}の削除に成功しました。`,
  // Format Delete Error
  MSG036: (name: string) => `${name}の削除に失敗しました。`,
  // Update Coupon Susscess
  MSG037: 'クーポンコードの更新に成功しました。',
  // Update Coupon Danger
  MSG038: 'クーポンコードの更新に失敗しました。',
  //Download file error
  MSG039: 'ファイルは存在していません。',
  //Agency not exist
  MSG040: '代理店は存在していません。',
  //Admin reset data user
  MSG041: '編集前の情報を復元しますか?',
  //Admin delete user success
  MSG042: '代理店の削除に成功しました。',
  //Admin delete user error
  MSG043: 'この代理店はメンバーを含めるため、削除できません。',
};
