import { NavigationGuard } from 'vue-router';
import { useUser } from '@/composables/useUser';
import { TOKEN_KEY, USER_KEY } from '@/constants';
import cookie from '@/utils/cookie';
import { UserType, AccountStatus } from '@/models/user.model';
import { localStorage } from '@/utils/localStorage';

export const memberAuth: NavigationGuard = async (to, from, next) => {
  const isAuthenticated = cookie.get(TOKEN_KEY) !== null;
  const { user, fetchUser } = useUser();
  const { userType, accountStatus } = user.value;
  const { isApp, token, isMail } = to?.query;
  if (isMail === 'true') {
    cookie.set('isMail', 'true');
  }

  if (isAuthenticated && userType === UserType.User) {
    if (isAuthenticated) {
      if (accountStatus === AccountStatus.Active) {
        next();
      } else {
        if (accountStatus === AccountStatus.UnregisteredCredit) {
          next('/payment');
        }
      }
    } else {
      next('/login');
    }
  } else {
    if (isApp === 'true') {
      if (token && token.length > 1) {
        if (isAuthenticated) {
          cookie.delete(TOKEN_KEY);
          localStorage.removeStorageItem(USER_KEY);
        }
        cookie.set(TOKEN_KEY, token);
        const res = await fetchUser();
        if (res.data) {
          if (res.data.accountStatus === AccountStatus.UnregisteredCredit) {
            next('/payment');
          } else {
            if (res.data.accountStatus === AccountStatus.Active) {
              next(to.path);
            }
          }
        }
      }
    } else {
      next('/login');
    }
  }
};
