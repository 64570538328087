import Api from './api';
import axiosIns from '@/service/httpClient';
class ZipCodeApi extends Api {
  constructor() {
    super('postalcode');
  }

  getZipCode(code: string | number) {
    return axiosIns({
      url: `/${this.uri}/get-postal-code/${code}`,
      method: 'get',
    });
  }

  getPrefecture() {
    return axiosIns({
      url: `/${this.uri}/get-prefecture`,
      method: 'get',
    });
  }

  getCityByPrefecture(prefecture: any) {
    return axiosIns({
      url: `/${this.uri}/get-city-by-prefecture/${prefecture}`,
      method: 'get',
    });
  }
}
export { ZipCodeApi as default };
