import { UserSignUpInterface } from '@/models/user.model';
import axiosIns from '@/service/httpClient';
import Api from './api';
class UserApi extends Api {
  constructor() {
    super('users');
  }

  getUser(query = '') {
    return axiosIns({
      url: `/${this.uri}/me`,
      method: 'get',
      params: query,
    });
  }

  signUp(resource: UserSignUpInterface) {
    return axiosIns({
      url: `/${this.uri}/register`,
      method: 'post',
      data: resource,
    });
  }

  verifyEmail(resource: object) {
    return axiosIns({
      url: `/${this.uri}/verify-email`,
      method: 'post',
      data: resource,
    });
  }

  resendMail(resource: object) {
    return axiosIns({
      url: `/${this.uri}/resend-confirm-mail`,
      method: 'post',
      data: resource,
    });
  }

  forgotPassword(resource: object) {
    return axiosIns({
      url: `/${this.uri}/send/forgot-password`,
      method: 'post',
      data: resource,
    });
  }

  resetPassword(resource: object) {
    return axiosIns({
      url: `/${this.uri}/reset-password`,
      method: 'post',
      data: resource,
    });
  }

  getAllAgencies(query = '') {
    return axiosIns({
      url: `/${this.uri}/get-all-agencies`,
      method: 'get',
      params: query,
    });
  }

  getUserAgency(query = {}) {
    return axiosIns({
      url: `/${this.uri}/get-user-agency`,
      method: 'get',
      params: query,
    });
  }

  unsubscribe(resource: object) {
    return axiosIns({
      url: `/${this.uri}/unsubscribe`,
      method: 'put',
      data: resource,
    });
  }

  exportCsvByAgency(query = '') {
    return axiosIns({
      url: `/${this.uri}/agency-export-csv`,
      method: 'get',
      params: query,
      responseType: 'blob',
    });
  }

  changePassword(resource: object) {
    return axiosIns({
      url: `/${this.uri}/change-password`,
      method: 'post',
      data: resource,
    });
  }

  editProfile(resource: object) {
    return axiosIns({
      url: `/${this.uri}/me`,
      method: 'put',
      data: resource,
    });
  }

  updateEmail(resource: object) {
    return axiosIns({
      url: `/${this.uri}/update-email`,
      method: 'put',
      data: resource,
    });
  }

  registerWater() {
    return axiosIns({
      url: `/${this.uri}/register-water`,
      method: 'post',
    });
  }
}
export { UserApi as default };
