export interface UserInterface {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  fullName: string;
  furiganaFullName?: string;
  furiganaFirstName?: string;
  furiganaLastName?: string;
  accountStatus: AccountStatus;
  phoneNumber: string;
  userType: UserType;
  address?: string;
  zipCode?: string;
  prefecture?: string;
  city?: string;
  subsequentAddress?: string;
  buildingNameAndRoomNumber?: string;
  createdAt: string;
  updatedAt: string;
  registerDate: string;
  expirationDate: string;
  isPaid: boolean;
  confirmPassword?: string;
  memo?: string;
  agencyCode?: string;
  isRegisteredPayment?: any;
  cardNumber?: string;
  telNoOfCard?: any;
  cardName?: string;
  cardExpirationDate?: string;
  emailOfCard?: string;
  disableNeightborTrouble?: boolean;
}
export interface UserSignUpInterface {
  firstName: string;
  lastName: string;
  furiganaFirstName: string;
  furiganaLastName: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  prefecture: string;
  city: string;
  subsequentAddress: string;
  buildingNameAndRoomNumber: string;
  agencyCode: string;
  password: string;
  confirmPassword: string;
}

export enum AccountStatus {
  InActive,
  Active,
  UnregisteredCredit,
}

export enum UserType {
  Admin,
  User,
  Agency,
}

export enum BankAccountType {
  Regular,
  Temporary,
}

interface SignUpInput {
  name: string;
  text: string;
}

export const INPUT_LIST: { [key: string]: SignUpInput } = {
  firstName: {
    name: 'firstName',
    text: '名前',
  },
  lastName: {
    name: 'lastName',
    text: '名前',
  },
  furiganaFirstName: {
    name: 'furiganaFirstName',
    text: '名前フリガナ',
  },
  furiganaLastName: {
    name: 'furiganaLastName',
    text: '名前フリガナ',
  },
  prefecture: {
    name: 'prefecture',
    text: '都道府県',
  },
  city: {
    name: 'city',
    text: '市区町村',
  },
  subsequentAddress: {
    name: 'subsequentAddress',
    text: '以降の住所',
  },
  buildingNameAndRoomNumber: {
    name: 'buildingNameAndRoomNumber',
    text: '建物名・部屋番号',
  },
  agencyCode: {
    name: 'agencyCode',
    text: '代理店コード',
  },
  phone: {
    name: 'phone',
    text: '電話番号',
  },
  phoneNumber: {
    name: 'phoneNumber',
    text: '電話',
  },
  email: {
    name: 'email',
    text: 'メールアドレス',
  },
  gender: {
    name: 'gender',
    text: '性別',
  },
  dateBirthday: {
    name: 'dateBirthday',
    text: '生年月日',
  },
  zipCode: {
    name: 'zipCode',
    text: '郵便番号',
  },
  password: {
    name: 'password',
    text: 'パスワード',
  },
  confirmPassword: {
    name: 'confirmPassword',
    text: 'パスワードの確認',
  },
  accountStatus: {
    name: 'accountStatus',
    text: 'アカウントステータス',
  },
  discussMessage: {
    name: 'discussMessage',
    text: 'ご相談内容',
  },
  contactMessage: {
    name: 'contactMessage',
    text: 'お問い合わせ内容',
  },
  numberCard: {
    name: 'numberCard',
    text: 'カード番号',
  },
  holderCard: {
    name: 'holderCard',
    text: 'カード名義',
  },
  expirationDate: {
    name: 'expirationDate',
    text: '有効期限',
  },
  securityCode: {
    name: 'securityCode',
    text: 'セキュリティコード',
  },
  companyName: {
    name: 'companyName',
    text: '会社名',
  },
  branchName: {
    name: 'branchName',
    text: '支社/支店名',
  },
  bankName: {
    name: 'bankName',
    text: '銀行名',
  },
  bankBranchName: {
    name: 'bankBranchName',
    text: '支店名',
  },
  bankAccountType: {
    name: 'bankAccountType',
    text: '口座種別',
  },
  bankAccountName: {
    name: 'bankAccountName',
    text: '口座名義',
  },
};

export interface AgencyInterface {
  email?: string;
  fullName?: string;
  furiganaFullName?: string;
  id: number;
}

export interface GetUsersParams {
  userType?: number | null;
  accountStatus?: number | null;
  currentPage: number | null;
  perPage: number | null;
  filter?: string | null;
  direction?: string | null;
  sortBy?: string | null;
  searchBy?: string | null;
  searchValue?: string | null;
  prefecture?: string | null;
  city?: string | null;
}

export interface PaidMemberInterface extends UserInterface {
  expiredCardDate?: string;
  money?: number;
}
