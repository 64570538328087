import { IS_ADMIN_KEY } from '@/constants';
import cookie from '@/utils/cookie';

export const formatDateTimeJapan = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');

  return `${year}年${month}月${day}日`;
};

export const getTabIndex = (route: string, tabsPathsDictionary: string[]) =>
  tabsPathsDictionary.findIndex((element) => element === route) || 0;

export const checkIsAdmin = () => {
  return cookie.get(IS_ADMIN_KEY) === 'true';
};

export const getCurrentMonth = () => {
  return new Date().getMonth() + 1;
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getMonth = (fullDate: any) => {
  if (fullDate) return new Date(fullDate).getMonth() + 1;
};

export const getYear = (fullDate: any) => {
  if (fullDate) return new Date(fullDate).getFullYear();
};

export const focusNextElement = (e: any) => {
  e.preventDefault();
  const input: any = Array.from(e.target.form.querySelectorAll('input  '));
  const textarea: any = Array.from(e.target.form.querySelectorAll('textarea  '));
  const inputs = [...input, ...(!!textarea && textarea)];
  const index = inputs.indexOf(e.target);

  if (e.code === 'Enter') {
    if (index < inputs.length && inputs[index + 1]) {
      inputs[index + 1].focus();
    }
  }
  if (e.code === 'ArrowUp') {
    if (index < inputs.length && inputs[index - 1]) {
      inputs[index - 1].focus();
    }
  }
};

export const onHandelTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const onHandleBottom = (heightElement: any) => {
  window.scrollTo({
    top: heightElement,
    behavior: 'smooth',
  });
};

export const handleDownloadFile = (res: any, name: string) => {
  const file = window.URL.createObjectURL(new Blob([res]));

  const docUrl = document.createElement('a');
  docUrl.href = file;
  docUrl.setAttribute('download', name);
  document.body.appendChild(docUrl);
  docUrl.click();
};

const dayOfMonth: any = {
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};

export const getDayOptions = (year: number, month: number) => {
  const dayOptions = [];
  for (let i = 1; i <= getNumberOfDaysInMonth(year, month); i++) {
    const dayOption = {
      text: `${i}日`,
      value: i,
    };
    dayOptions.push(dayOption);
  }
  return dayOptions;
};

const getNumberOfDaysInMonth = (year: number, month: number) => {
  const isLeapYear = year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);

  if (month == 2 && isLeapYear) {
    return 29;
  } else {
    const numberDayOfMonth: any = Object.values(dayOfMonth)[month - 1];
    return numberDayOfMonth;
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  try {
    const lengthPhoneNumber = phoneNumber.length;
    const phoneNumberConvert = `${phoneNumber.substring(
      0,
      lengthPhoneNumber - 8
    )}-${phoneNumber.substring(
      lengthPhoneNumber - 8,
      lengthPhoneNumber - 4
    )}-${phoneNumber.substring(lengthPhoneNumber - 4, lengthPhoneNumber)}`;

    return phoneNumberConvert;
  } catch {
    return;
  }
};
