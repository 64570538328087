import AuthApi from '@/api/authApi';
import { useLoading } from '@/composables/useLoading';
import { TOKEN_KEY, USER_KEY } from '@/constants/index';
import { SignInBody } from '@/models/auth.model';
import cookie from '@/utils/cookie';
import { useRouter, useRoute } from 'vue-router/composables';
import { UserType, AccountStatus } from '@/models/user.model';
import { localStorage } from '@/utils/localStorage';

export const useAuth = () => {
  const authApi = new AuthApi();
  const { $loading, isLoading } = useLoading();
  const router = useRouter();
  const route = useRoute();

  const signIn = async (provideUser: SignInBody) => {
    try {
      $loading.start();
      const res = await authApi.login(provideUser);

      if (res.data) {
        cookie.set(TOKEN_KEY, res?.data?.token);
        return Promise.resolve(res);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      $loading.finish();
    }
  };

  const handleLogout = () => {
    cookie.delete(TOKEN_KEY);
    localStorage.removeStorageItem(USER_KEY);
    if (route.name === 'top') {
      location.reload();
    } else {
      router.push('/');
    }
  };

  const handleRedirectProfile = (user: any) => {
    const { Admin, User, Agency } = UserType;
    switch (user.userType) {
      case Admin:
        router.push('/admin');
        break;
      case User:
        if (user.accountStatus === AccountStatus.Active) {
          router.push('/member');
        } else {
          if (user.accountStatus === AccountStatus.UnregisteredCredit) {
            router.push('/payment');
          }
        }
        break;
      case Agency:
        router.push('/agency');
        break;
      default:
        router.push('/');
    }
  };

  return {
    signIn,
    isLoading,
    handleLogout,
    handleRedirectProfile,
  };
};
