import ZipCodeApi from '@/api/zipCodeApi';
import { EventType } from '@/models/helpers.mode';
import { INPUT_LIST } from '@/models/user.model';
import { MessageClient } from '@/shared/msg';
import { validatorEmail, validatorPassword, validatorPhoneNumber } from '@/utils/validator';

const zipCodeApi = new ZipCodeApi();

export const validateEmptyField = (field: any) => {
  field.isError = true;
  field.msg = MessageClient.MSG001(field.title);
};

const updateAddressData = (fields: Array<string>, addressData: any, dataNeedValidate: any) => {
  if (addressData) {
    fields.map((field) => {
      dataNeedValidate[field as keyof typeof dataNeedValidate].value = addressData[`${field}JA`];
      dataNeedValidate[field as keyof typeof dataNeedValidate].isError = false;
    });
  }
};

const validateConfirmPassword = (dataNeedValidate: any, input: any) => {
  if (dataNeedValidate && dataNeedValidate.confirmPassword.value) {
    if (dataNeedValidate.confirmPassword.value === input.value) {
      dataNeedValidate.confirmPassword.isError = false;
    } else {
      dataNeedValidate.confirmPassword.isError = true;
      dataNeedValidate.confirmPassword.msg = MessageClient.MSG005;
    }
  }
};

const validateAgency = (code: string, agencyData: any, data: any) => {
  if (data) {
    const branchNameNew: any = agencyData.filter((el: any) => el.id.toString() === code.toString());
    if (branchNameNew.length > 0) {
      data.value = branchNameNew[0].companyName;
    } else {
      data.value = '';
    }
  }
  return agencyData.some((el: any) => el.id.toString() === code.toString());
};

export const validateFields = async (
  input: any,
  eventType: string,
  name: string,
  dataNeedValidate: any,
  data: any = {}
) => {
  if (eventType === EventType.Focus) {
    input.isError = false;
    if (name === INPUT_LIST.zipCode.name) {
      input.isFocusing = true;
    }
  } else {
    switch (name) {
      case INPUT_LIST.email.name:
        if (!input.value) {
          validateEmptyField(input);
          return;
        } else {
          if (!validatorEmail(input.value)) {
            input.isError = true;
            input.msg = MessageClient.MSG003;
          }
        }
        break;
      case INPUT_LIST.phoneNumber.name:
      case INPUT_LIST.phone.name:
        if (!input.value) {
          validateEmptyField(input);
          return;
        } else {
          if (!validatorPhoneNumber(input.value)) {
            input.isError = true;
            input.msg = MessageClient.MSG002;
          }
        }
        break;
      case INPUT_LIST.zipCode.name:
        if (!input.value) {
          validateEmptyField(input);
          input.isFocusing = false;
          return;
        } else {
          try {
            const res = await zipCodeApi.getZipCode(dataNeedValidate.zipCode.value);
            if (res) {
              const { data } = res;
              const addressFields = [
                INPUT_LIST.prefecture.name,
                INPUT_LIST.city.name,
                INPUT_LIST.subsequentAddress.name,
              ];
              updateAddressData(addressFields, data, dataNeedValidate);
            }
          } catch (error) {
            console.log(error);
          } finally {
            input.isFocusing = false;
          }
        }
        break;
      case INPUT_LIST.password.name:
        if (!input.value) {
          validateEmptyField(input);
          return;
        } else {
          if (!validatorPassword(input.value)) {
            input.isError = true;
            input.msg = MessageClient.MSG004;
          }
          validateConfirmPassword(dataNeedValidate, input);
        }
        break;
      case INPUT_LIST.confirmPassword.name:
        if (!input.value) {
          validateEmptyField(input);
        } else {
          if (!(dataNeedValidate.password.value === input.value)) {
            input.isError = true;
            input.msg = MessageClient.MSG005;
          }
        }
        break;
      case INPUT_LIST.numberCard.name:
        if (!input.value) {
          validateEmptyField(input);
          return;
        } else {
          if (input.value.length < 13) {
            input.isError = true;
            input.msg = MessageClient.MSG026;
          }
        }
        break;
      case INPUT_LIST.securityCode.name:
        if (!input.value) {
          validateEmptyField(input);
          return;
        } else {
          if (input.value.length < 3) {
            input.isError = true;
            input.msg = MessageClient.MSG027;
          }
        }
        break;
      case INPUT_LIST.buildingNameAndRoomNumber.name:
        break;
      case INPUT_LIST.agencyCode.name:
        if (!input.value) {
          validateEmptyField(input);
          return;
        } else {
          if (!validateAgency(input.value, dataNeedValidate, data)) {
            input.isError = true;
            input.msg = MessageClient.MSG040;
          }
        }
        break;
      default:
        if (!input.value) {
          validateEmptyField(input);
        }
        break;
    }
  }
};
