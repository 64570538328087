<template>
  <footer class="page-footer bg-primary d-flex justify-content-center">
    <span class="text-white">Copyright © 不動産研究会 All rights reserved. 禁無断転載</span>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageHeader',
});
</script>
