export const MONTH_OPTION = [
  { text: '1月', value: 1 },
  { text: '2月', value: 2 },
  { text: '3月', value: 3 },
  { text: '4月', value: 4 },
  { text: '5月', value: 5 },
  { text: '6月', value: 6 },
  { text: '7月', value: 7 },
  { text: '8月', value: 8 },
  { text: '9月', value: 9 },
  { text: '10月', value: 10 },
  { text: '11月', value: 11 },
  { text: '12月', value: 12 },
];

export const YEAR_OPTION = [
  ...Array.from({ length: 100 }, (_, i) => {
    const y = new Date().getFullYear() - 15 + i;
    return { text: `${y}年`, value: y };
  }),
];

export const YEAR_BIRTHDAY_OPTION = [
  ...Array.from({ length: 100 }, (_, i) => {
    const y = new Date().getFullYear() - i;
    return { text: `${y}年`, value: y };
  }),
];

export const STATUS_OPTION = [
  { label: '非アクティブ', value: false },
  { label: 'アクティブ', value: true },
];

export const LAWYER_OPTION = [
  { text: '文書発信対応', value: '文書発信対応' },
  { text: 'いいえ', value: 'いいえ' },
];

export const SEX_TYPE_OPTION = [
  { item: 1, name: '男性' },
  { item: 2, name: '女性' },
];

export const OPTION_TYPE_ACCOUNT = [
  { item: 1, name: '当座' },
  { item: 0, name: '普通' },
];
