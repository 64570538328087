export const TOKEN_KEY = 't_k';
export const USER_KEY = 'u_k';
export const EMAIL_KEY = 'e_k';
export const IS_ADMIN_KEY = 'a_k';
export const IS_LOGIN_KEY = 'l_k';
export const CREDIT_DATA_KEY = 'crd_k';
export const TOKEN_CREDIT_DATA_KEY = 't_crd_k';
export const QUICK_CHARGE_CREDIT_NUMBER = '8888888888888888';
export const CREDIT_FIRST_TIME_MONEY = '0';
export const CREDIT_QUICK_MONEY = '400';
export const CREDIT_QUICK_ACTION = 'quick';
export const CREDIT_NEW_CARD_ACTION = 'newcard';
export const IS_SAVED_CARD_KEY = 's_c';
export const DATE_FORMAT = 'YYYY/MM/DD';

export const PER_PAGE_OPTIONS = [
  { value: 5, text: '5' },
  { value: 10, text: '10' },
  { value: 25, text: '25' },
  { value: 50, text: '50' },
  { value: 100, text: '100' },
];

export const DEFAULT_PAGE = 1;
export const DEFAULT_ROWS_PER_PAGE = 10;

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum MailType {
  Inbox = '1',
  Sent = '2',
  Deleted = '3',
}

export enum TroubleType {
  NeighborTrouble = 1,
  WaterTrouble = 2,
  Inquiry = 3,
  RentalAppliance = 4,
}

export enum SexType {
  Male = 1,
  Female = 2,
}
