import { TOKEN_KEY } from '@/constants/index';
import StatusCode from '@/models/statusCode.model';
import cookie from '@/utils/cookie';
import axios, { AxiosError } from 'axios';
import Vue from 'vue';

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosIns.interceptors.request.use(
  (config) => {
    const token = cookie.get(TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const handleError = (error: AxiosError) => {
  if (error) {
    const { status } = error as { status: number };

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
    }
  }

  return Promise.reject(error);
};

axiosIns.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },

  (error) => {
    return handleError(error.response);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
